import React, { useEffect, useState } from "react";

import PersonPinCircleRoundedIcon from "@mui/icons-material/PersonPinCircleRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import {
  Button,
  ListItem,
  ListItemText,
  Grid,
  Box,
  Typography,
  List,
  Divider,
  ListItemButton,
  ListItemIcon,
  Skeleton,
} from "@mui/material";

import BasicModal from "../Modal/Modal";

import useStyles from "./styles";
import { useTheme } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useAuth } from "../../hooks/AuthContext";
import { useQuery } from "react-query";
import { DADOSBANCARIOS } from "../../util/typesReactQuery";
import useAlert from "../../hooks/useAlert";
import {
  getDadosBancariosCliente,
  desativaAtivaDadosBancarios,
} from "../../api/DadosBancarios";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Cadastrar from "./Cadastrar";
import { ButtonCustom } from "../Botoes/Button";
import { stringAvatar } from "../../util/helpers";

export default function DadosBancarios({
  isLoading = false,
  formaPagamento,
  onNext,
  isOperacao = false,
}) {
  const classes = useStyles();
  const [dadosBancarios, setDadosBancarios] = useState([]);
  const theme = useTheme();
  const { Alert, showAlertError, showAlertInfo, showAlertSuccess } = useAlert();
  const { user } = useAuth();
  const [showModalNew, setShowModalNew] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loadingAction, setLoadingAction] = useState(false);

  useEffect(() => {
    if (!!error) {
      showAlertError(error);
      setError("");
    }
  }, [error]);

  useEffect(() => {
    if (!!successMessage) {
      showAlertSuccess(successMessage);
      setSuccessMessage("");
    }
  }, [successMessage]);

  const { refetch: refetchDadosBancarios } = useQuery(
    DADOSBANCARIOS,
    () => getDadosBancariosCliente(user.id, false),
    {
      onSuccess: (data) => {
        if (!data || !!data.errorMessage)
          showAlertError(
            `Ops, algo deu errado, por favor informe o suporte o seguinte error: Não foi possivel verificar se a loja esta aberta - R1B94 `
          );

        setDadosBancarios(data.data);
      },
      onError: (error) => {
        showAlertError(
          `Ops, algo deu errado, por favor informe o suporte o seguinte error: ${error} - RD88E `
        );
      },
    }
  );

  const handlerNext = (event, dadosBancarios) => {
    let pagamento = {
      dadoBancario: {
        id: dadosBancarios.id,
        idBancoNacional: dadosBancarios.idBancoNacional,
        agencia: dadosBancarios.numeroAgencia,
        digitoAgencia: dadosBancarios.digitoAgencia,
        numeroConta: dadosBancarios.numeroConta,
        digitoConta: dadosBancarios.digitoConta,
        idTipoContaBancaria: dadosBancarios.idTipoContaBancaria,
        chavePix: dadosBancarios.chavePIX,
      },
    };

    onNext(event, pagamento);
  };

  const desativaAtiva = async (idDadosBancarios) => {
    setLoadingAction(true);
    const response = await desativaAtivaDadosBancarios(
      user.id,
      idDadosBancarios
    );

    if (!!response.errorMessage)
      throw new Error(
        `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${response.errorMessage} - R6C28`
      );

    if (!!!response)
      throw new Error(
        `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi possível ativar/desativar este Banco - R2937`
      );

    showAlertSuccess("Alteração feita com sucesso!");
    refetchDadosBancarios();
    setLoadingAction(false);
  };

  return (
    <>
      <Alert />
      <Grid sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <ButtonCustom
          size="small"
          variant="contained"
          style={{
            cursor: "pointer",
            marginBottom: 20,
          }}
          onClick={() => setShowModalNew(true)}
        >
          {`+ Adicionar Novo Banco`}
        </ButtonCustom>
      </Grid>

      <List
        dense
        sx={{
          width: "100%",
          maxWidth: "100%",
          bgcolor: "background.paper",
          position: "relative",
        }}
      >
        {(isLoading || loadingAction) && (
          <>
            <Skeleton
              animation="wave"
              height={200}
              width={"100%"}
              sx={{ marginBottom: "-3rem" }}
            />
            <Skeleton
              animation="wave"
              height={200}
              width={"100%"}
              sx={{ marginBottom: "-3rem" }}
            />
            <Skeleton animation="wave" height={200} width={"100%"} />
          </>
        )}

        {!isLoading && !loadingAction && dadosBancarios?.length > 0
          ? dadosBancarios.map((d) => {
              return (
                d.id > 0 &&
                (isOperacao ? (
                  d.ativo && (
                    <ListItem sx={{ padding: 0 }} key={d.id}>
                      <ListItemButton
                        sx={{
                          padding: 2,
                        }}
                        onClick={(event) => handlerNext(event, d)}
                      >
                        <ListItemIcon>
                          <AccountBalanceIcon />
                        </ListItemIcon>
                        <ListItemText
                          multiline
                          id={d.id}
                          primary={d.bancoNacional.descricao}
                          style={{ fontWeight: "bold" }}
                        />
                        <List>
                          {d.chavePIX?.length > 1 ? (
                            <>
                              <ListItemText
                                primary={"Pix"}
                                secondary={`Chave: ${d.chavePIX}`}
                              />
                            </>
                          ) : (
                            <>
                              <ListItemText
                                primary={"Agência"}
                                secondary={`${d.numeroAgencia}-${d.digitoAgencia}`}
                              />
                              <ListItemText
                                primary={"Conta"}
                                secondary={`${d.numeroConta}-${d.digitoConta}`}
                              />
                            </>
                          )}
                        </List>
                        <ArrowForwardIosIcon
                          sx={{ color: "#444", fontSize: 14, marginLeft: 2 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  )
                ) : (
                  <>
                    <Divider />
                    <Grid sx={{ m: 1 }}>
                      <ListItem
                        sx={{
                          padding: 2,
                          display: "flex",
                          boxShadow: "0px 3px 3px 3px #efefef",
                          display: "block",
                        }}
                        key={d.id}
                      >
                        <Grid
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "end",
                          }}
                        >
                          <Grid>
                            <ListItemIcon>
                              <AccountBalanceIcon fontSize="small" />
                            </ListItemIcon>

                            {!!d.chavePIX && d.chavePIX.length > 1 ? (
                              <>
                                <ListItemText
                                  multiline
                                  id={d.id}
                                  primary={d.bancoNacional.descricao}
                                  style={{ fontWeight: "bold" }}
                                  sx={{ flexBasis: "10%" }}
                                />
                                <List sx={{ flexBasis: "40%" }}>
                                  <ListItemText
                                    primary={"Pix"}
                                    secondary={`Chave: ${d.chavePIX}`}
                                  />
                                </List>
                              </>
                            ) : (
                              <>
                                <ListItemText
                                  multiline
                                  id={d.id}
                                  primary={d.bancoNacional.descricao}
                                  style={{ fontWeight: "bold" }}
                                  sx={{ flexBasis: "10%" }}
                                />
                                <List sx={{ flexBasis: "40%" }}>
                                  <ListItemText
                                    primary={"Agência"}
                                    secondary={`${d.numeroAgencia}-${d.digitoAgencia}`}
                                  />
                                  <ListItemText
                                    primary={"Conta"}
                                    secondary={`${d.numeroConta}-${d.digitoConta}`}
                                  />
                                </List>
                              </>
                            )}
                          </Grid>
                          <Grid>
                            <List sx={{ flexBasis: "14%" }}>
                              <Button
                                component="label"
                                size="small"
                                onClick={() => {
                                  desativaAtiva(d.id);
                                }}
                              >
                                {d.ativo ? <>Desativar</> : <>Ativar</>}
                              </Button>
                            </List>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </Grid>
                  </>
                ))
              );
            })
          : !isLoading &&
            !loadingAction && (
              <Box sx={{ margin: "auto", textAlign: "center", mt: 4 }}>
                <AccountBalanceRoundedIcon
                  color="primary"
                  sx={{ fontSize: 40, margin: "auto" }}
                ></AccountBalanceRoundedIcon>

                <Typography sx={{ fontSize: 17 }}>
                  Nenhum banco encontrado
                </Typography>
              </Box>
            )}
      </List>

      <BasicModal
        show={showModalNew}
        close={() => setShowModalNew(!showModalNew)}
      >
        <Grid sx={{ m: 2 }}>
          <Cadastrar
            refetchDadosBancarios={refetchDadosBancarios}
            setError={setError}
            setShowModal={setShowModalNew}
            setSuccessMessage={setSuccessMessage}
          />
        </Grid>
      </BasicModal>
    </>
  );
}
