import { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Skeleton from "@mui/material/Skeleton";
import useStyles from "./styles";
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "2px !important",
    },
    "&:hover fieldset": {
      borderRadius: "2px !important",
    },
  },
});

export default function CampoTexto({
  name,
  value,
  fullWidth,
  sx,
  handlerChange,
  onBlur,
  label,
  size,
  enable,
  isLoading = false,
  required,
  autoFocus,
  maximoDigitos,
  startAdornment
}) {
  const [isError, setIsError] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (required) setIsError(value === null || value.length <= 0);
  }, [value]);

  return (
    <>
      {isLoading && (
        <Skeleton
          variant="rectangular"
          height={40}
          sx={sx}
          animation={false}
          className={classes.root}
        />
      )}
      {!isLoading && (
        <FormControl fullWidth={fullWidth} size="medium" variant="outlined">
          <CssTextField
        
          sx={{mt:1, mb:1}}
            required={required}
            fullWidth={fullWidth ?? true}
            id={label}
            label={label}
            size={size ?? "medium"}
            defaultValue=""
            name={name}
            value={value}
            autoFocus={autoFocus}
            onBlur={onBlur}
            onChange={handlerChange}
            inputProps={{
              startAdornment:{startAdornment},
              maxLength:
                maximoDigitos == null || maximoDigitos == 0
                  ? 999
                  : maximoDigitos,
            }}
            // helperText={isError ? `${label} obrigatório!` : ""}
            // error={isError}
            className={classes.root}
            disabled={!enable}
          />
        </FormControl>
      )}
    </>
  );
}
