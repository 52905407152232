import React from 'react';
import { IconButton } from '@mui/material';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import useStyle from './style'

export default function IconButtonDelete({ onClick }) {
      const classes = useStyle();
      return (
            <>
                  <IconButton
                        className={classes.IconButton}
                        onClick={onClick}
                        sx={{ p: 0, m: 0, ml: 1 }}
                  >
                        <DeleteOutlineIcon
                              fontSize="inherit"
                              sx={{ fontSize: "1.2rem" }}
                              color="inherit"
                        />
                  </IconButton>
            </>
      );
}