import Login from "./pages/Login/index";
import VerificacaoEmail from "./pages/VerificacaoEmail/index";
import RecuperarSenha from "./pages/RecuperarSenha/index";
import LojaFechada from "./pages/LojaFechada/index";
import NovaSenha from "./pages/NovaSenha/index";
import Cadastro from "./pages/Cadastro/index";
import DadosCliente from "./pages/DadosCliente";
import NetworkError from "./pages/NetworkError/index";
import { Routes, Route } from "react-router-dom";
import { MinhaConta } from "./pages/MinhaConta/Home/index";
import { MeusDados } from "./pages/MinhaConta/MeusDados/index";
import { Criptomoeda } from "./pages/MinhaConta/Criptomoeda/Home";
import { ComprarCripto } from "./pages/MinhaConta/Criptomoeda/ComprarCripto/ComprarCripto";
import { Ativos } from "./pages/MinhaConta/Criptomoeda/Ativos/Ativos";
import { Historico } from "./pages/MinhaConta/Criptomoeda/Historico";
import { AlterarSenha } from "./pages/MinhaConta/AlterarSenha/Index";
import { Notificacoes } from "./pages/MinhaConta/Notificacoes";
import { MeusPedidos } from "./pages/MinhaConta/MeusPedidos";
import { ConvidarAmigos } from "./pages/MinhaConta/ConvidarAmigos/Index";
import ClienteLimiteOperacional from "./pages/ClienteLimiteOperacional";
import ClienteLimiteOperacionalPlus from "./pages/ClienteLimiteOperacionalPlus";
import PreLogin from "./pages/PreLogin";
import CarrinhoVazio from "./pages/CarrinhoVazio";
import AuthRoute from "./util/AuthRoute";
import PrivateRoute from "./util/PrivateRoute";
import RegistrationRoute from "./util/RegistrationRoute";
// TODO: remover posterior
import TesteComponente from "./pages/TesteComponente";
import { PedidoCambio } from "./components";
// Fluxo de transferencia internacional envio
import { Vitrine as VitrineEnvio } from "./pages/Carrinho/TransferenciaInternacional/Envio/Vitrine";
import ItemEnvio from "./pages/Carrinho/TransferenciaInternacional/Envio/ItemEnvio";
import { Pagamento as PagamentoEnvio } from "./pages/Carrinho/TransferenciaInternacional/Envio/Pagamento";
import ConfirmacaoEnvio from "./pages/Carrinho/TransferenciaInternacional/Envio/ConfirmacaoEnvio";
import Beneficiarios from "./pages/Carrinho/TransferenciaInternacional/Envio/Beneficiarios";

// Fluxo de transferencia internacional receimento
import { Vitrine as VitrineRecebimento } from "./pages/Carrinho/TransferenciaInternacional/Recebimento/Vitrine";
import ItemRecebimento from "./pages/Carrinho/TransferenciaInternacional/Recebimento/ItemRecebimento";
import { Pagamento as PagamentoRecebimento } from "./pages/Carrinho/TransferenciaInternacional/Recebimento/Pagamento";
import ConfirmacaoRecebimento from "./pages/Carrinho/TransferenciaInternacional/Recebimento/ConfirmacaoRecebimento";
import Remetente from "./pages/Carrinho/TransferenciaInternacional/Recebimento/Recebedor";

import Carrinho from "./pages/Carrinho";

// Fluxo de especie compra
import VitrineCompraEspecie from "./pages/Carrinho/Turismo/Vitrine/VitrineCompraEspecie";

// Fluxo de especie venda
import VitrineVendaEspecie from "./pages/Carrinho/Turismo/Vitrine/VitrineVendaEspecie";

import VitrineCompraSaldoCartao from "./pages/Carrinho/Turismo/Vitrine/VitrineCompraSaldoCartao";

import VitrineRecargaCartao from "./pages/Carrinho/Turismo/Vitrine/VitrineRecargaCartao";

import VitrineVendaCartao from "./pages/Carrinho/Turismo/Vitrine/VitrineVendaCartao";

import EntregaTurismo from "./pages/Carrinho/Turismo/EntregaTurismo";

import ItemCarrinhoTurismo from "./pages/Carrinho/Turismo/ItemCarrinhoTurismo";

import ItemCarrinhoTurismoOuro from "./pages/Carrinho/Ouro/ItemCarrinhoOuro";

import { Pagamento as PagamentoTurismo } from "./pages/Carrinho/Turismo/Pagamento";
import { Pagamento as PagamentoOuro } from "./pages/Carrinho/Ouro/Pagamento";

import { Confirmacao as ConfirmacaoTurismo } from "./pages/Carrinho/Turismo/Confirmacao";

import Recebedor from "./pages/RecebedorEstrangeiro";

import { Pagamento as SeguroViagemPagamento } from "./pages/SeguroViagem/Pagamento";

import { Passageiros as SeguroViagemPassageiros } from "./pages/SeguroViagem/Passageiros";

import VitrineSeguro from "./pages/SeguroViagem/VitrineSeguro";

import Ouro from "./pages/Carrinho/Ouro/Vitrine";
import Contato from "./pages/Contato";

export function AppRoutes() {
  return (
    <Routes>



      <Route
        index
        element={
          <AuthRoute>
            <Login />
          </AuthRoute>
        }
      />
      <Route path="/Login">
        <Route path=":error"
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          } />
        <Route path=":isNotPerfilLimite/:type"
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          } />
        <Route path=""
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          } />
      </Route>



      <Route
        path="/VerificacaoEmail"
        element={
          // <AuthRoute>
          <VerificacaoEmail />
          // </AuthRoute>
        }
      />
      <Route
        path="RecuperarSenha"
        element={
          <AuthRoute>
            <RecuperarSenha />
          </AuthRoute>
        }
      />
      <Route path="novaSenha" element={<NovaSenha />} />
      <Route path="cadastro" element={<Cadastro />} />
      <Route path="NetworkError" element={<NetworkError />} />


      <Route
        path="/PreLogin"
        element={
          <PreLogin />
        }
      />

      <Route path="/MinhaConta">
        <Route path=":isNotPerfilLimite"
          element={
            <PrivateRoute>
              <MinhaConta />
            </PrivateRoute>
          } />
        <Route path=":isNotPerfilLimite/:type"
          element={
            <PrivateRoute>
              <MinhaConta />
            </PrivateRoute>
          } />
        <Route path=""
          element={
            <PrivateRoute>
              <MinhaConta />
            </PrivateRoute>
          } />
      </Route>


      <Route
        path="DadosCliente"
        element={
          <PrivateRoute>
            <DadosCliente />
          </PrivateRoute>
        }
      />
      <Route
        path="ClienteLimiteOperacional"
        element={
          <RegistrationRoute>
            <ClienteLimiteOperacional />
          </RegistrationRoute>
        }
      />
      <Route
        path="ClienteLimiteOperacionalPlus"
        element={
          <PrivateRoute>
            <ClienteLimiteOperacionalPlus />
          </PrivateRoute>
        }
      />
      <Route
        path="MeusDados"
        element={
          <PrivateRoute>
            <MeusDados />
          </PrivateRoute>
        }
      />
      <Route
        path="MinhaConta/AlterarSenha"
        element={
          <PrivateRoute>
            <AlterarSenha />
          </PrivateRoute>
        }
      />
      <Route
        path="MinhaConta/ConvidarAmigos"
        element={
          <PrivateRoute>
            <ConvidarAmigos />
          </PrivateRoute>
        }
      />
      <Route
        path="Criptomoeda"
        element={
          <PrivateRoute>
            <Criptomoeda />
          </PrivateRoute>
        }
      />
      <Route
        path="Criptomoeda/ComprarCripto"
        element={
          <PrivateRoute>
            <ComprarCripto />
          </PrivateRoute>
        }
      />
      <Route
        path="Criptomoeda/Ativos"
        element={
          <PrivateRoute>
            <Ativos />
          </PrivateRoute>
        }
      />
      <Route
        path="/Historico"
        element={
          <PrivateRoute>
            <Historico />
          </PrivateRoute>
        }
      />

      <Route
        path="/CarrinhoVazio"
        element={
          <PrivateRoute>
            <CarrinhoVazio />
          </PrivateRoute>
        }
      />
      <Route
        path="/Notificacoes"
        element={
          <PrivateRoute>
            <Notificacoes />
          </PrivateRoute>
        }
      />
      <Route
        path="/Recebedor"
        element={
          <PrivateRoute>
            <Recebedor />
          </PrivateRoute>
        }
      />
      <Route
        path="/MeusPedidos"
        element={
          <PrivateRoute>
            <MeusPedidos />
          </PrivateRoute>
        }
      />

      <Route
        path="/LojaFechada"
        element={
          <PrivateRoute>
            <LojaFechada />
          </PrivateRoute>
        }
      />
      {/* TODO: REMOVER POSTERIORMENTE */}
      <Route
        path="/TesteComponente"
        element={
          <PrivateRoute>
            <TesteComponente />
          </PrivateRoute>
        }
      />

      {/* Transferencia internacional envio */}
      <Route
        path="carrinho/transferencia-internacional/envio/vitrine"
        element={
          <PrivateRoute>
            <VitrineEnvio />
          </PrivateRoute>
        }
      />

      <Route
        path="carrinho/transferencia-internacional/envio/itemEnvio"
        element={
          <PrivateRoute>
            <ItemEnvio />
          </PrivateRoute>
        }
      />

      <Route
        path="carrinho/transferencia-internacional/envio/pagamento"
        element={
          <PrivateRoute>
            <PagamentoEnvio />
          </PrivateRoute>
        }
      />

      <Route
        path="Envio/PedidoConcluidoPagamentoEscolhido/:idPedido"
        element={
          <PrivateRoute>
            <ConfirmacaoEnvio />
          </PrivateRoute>
        }
      />

      <Route
        path="Recebimento/PedidoConcluidoPagamentoEscolhido/:idPedido"
        element={
          <PrivateRoute>
            <ConfirmacaoRecebimento />
          </PrivateRoute>
        }
      />
      <Route
        path="carrinho/transferencia-internacional/envio/beneficiario"
        element={
          <PrivateRoute>
            <Beneficiarios />
          </PrivateRoute>
        }
      />

      {/* Transferencia internacional recebimento */}
      <Route
        path="carrinho/transferencia-internacional/recebimento/vitrine"
        element={
          <PrivateRoute>
            <VitrineRecebimento />
          </PrivateRoute>
        }
      />

      <Route
        path="carrinho/transferencia-internacional/recebimento/itemRecebimento"
        element={
          <PrivateRoute>
            <ItemRecebimento />
          </PrivateRoute>
        }
      />

      <Route
        path="carrinho/transferencia-internacional/recebimento/pagamento"
        element={
          <PrivateRoute>
            <PagamentoRecebimento />
          </PrivateRoute>
        }
      />

      <Route
        path="carrinho/transferencia-internacional/recebimento/confirmacao"
        element={
          <PrivateRoute>
            <ConfirmacaoRecebimento />
          </PrivateRoute>
        }
      />

      <Route
        path="carrinho/transferencia-internacional/recebimento/remetente"
        element={
          <PrivateRoute>
            <Remetente />
          </PrivateRoute>
        }
      />

      <Route
        path="carrinho/turismo-compra/especie/vitrine"
        element={
          <PrivateRoute>
            <VitrineCompraEspecie />
          </PrivateRoute>
        }
      />

      <Route
        path="carrinho/turismo-venda/especie/vitrine"
        element={
          <PrivateRoute>
            <VitrineVendaEspecie />
          </PrivateRoute>
        }
      />

      <Route
        path="carrinho/turismo-compra/cartao/vitrine"
        element={
          <PrivateRoute>
            <VitrineVendaCartao />
          </PrivateRoute>
        }
      />

      <Route
        path="carrinho/turismo-compra/cartao-recarga/vitrine"
        element={
          <PrivateRoute>
            <VitrineRecargaCartao />
          </PrivateRoute>
        }
      />

      <Route
        path="carrinho/turismo-venda/cartao-saldo/vitrine"
        element={
          <PrivateRoute>
            <VitrineCompraSaldoCartao />
          </PrivateRoute>
        }
      />

      <Route
        path="carrinho/turismo/entrega"
        element={
          <PrivateRoute>
            <EntregaTurismo />
          </PrivateRoute>
        }
      />

      <Route
        path="carrinho/turismo/entrega/:isOuro"
        element={
          <PrivateRoute>
            <EntregaTurismo />
          </PrivateRoute>
        }
      />

      <Route
        path="carrinho/turismo/pagamento/:idTipoPedido/:isRecarga"
        element={
          <PrivateRoute>
            <PagamentoTurismo />
          </PrivateRoute>
        }
      />

      <Route
        path="carrinho/turismo/itens"
        element={
          <PrivateRoute>
            <ItemCarrinhoTurismo />
          </PrivateRoute>
        }
      />

      <Route
        path="/turismo/PedidoConcluidoPagamentoEscolhido/:idPedido"
        element={
          <PrivateRoute>
            <ConfirmacaoTurismo />
          </PrivateRoute>
        }
      />

      <Route
        path="/carrinho/Compra-ouro/vitrine"
        element={
          <PrivateRoute>
            <Ouro />
          </PrivateRoute>
        }
      />

      <Route
        path="carrinho/Ouro/pagamento/:idTipoPedido"
        element={
          <PrivateRoute>
            <PagamentoOuro />
          </PrivateRoute>
        }
      />

      <Route
        path="carrinho/Ouro/itens"
        element={
          <PrivateRoute>
            <ItemCarrinhoTurismoOuro />
          </PrivateRoute>
        }
      />

      <Route
        path="MinhaConta/contato"
        element={
          <PrivateRoute>
            <Contato />
          </PrivateRoute>
        }
      />

      <Route
        path="/Carrinho"
        element={
          <PrivateRoute>
            <Carrinho />
          </PrivateRoute>
        }
      />

      <Route
        path="/SeguroViagem"
        element={
          <PrivateRoute>
            <VitrineSeguro />
          </PrivateRoute>
        }
      />

      <Route
        path="/SeguroViagem/Passageiros"
        element={
          <PrivateRoute>
            <SeguroViagemPassageiros />
          </PrivateRoute>
        }
      />

      <Route
        path="/SeguroViagem/Pagamento"
        element={
          <PrivateRoute>
            <SeguroViagemPagamento />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
