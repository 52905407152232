import Button from "@mui/material/Button";
import useStyles from "./style";
import { CircularProgress, Skeleton } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
/**
 *
 * @param {string} variant options?: text => padrões de texto;
 * contained => valor padrão, deixa o botão com designer padrão de botão;
 * outlined => permanece designer de botão mais sem a cor de fundo.
 * @param {obj} className: informa uma classe de estylo a ser usada no botão
 * @param {function} Handle dispara alguam ação especifica, equivale ao onClick
 * @returns retorna um botão customizado
 */

function getLuminance(hexColor) {
  // Converte o valor hexadecimal em componentes RGB
  const red = parseInt(hexColor.substr(1, 2), 16);
  const green = parseInt(hexColor.substr(3, 2), 16);
  const blue = parseInt(hexColor.substr(5, 2), 16);

  // Calcula a luminosidade com base na fórmula adequada
  return (0.299 * red + 0.587 * green + 0.114 * blue) / 255;
}

export default function ButtonAcaoGrande(props) {
  const classes = useStyles();
  const style = { ...props.sx, mt: 1, mb: 0 };

  const bgColor = props.color ? props.color : "primary"; // Cor de fundo padrão, caso não seja especificada
  const textContrastColor = getLuminance(bgColor) > 0.8 ? "#000" : "#fff"; // Cor de texto com base na luminosidade

  return props.loading ? (
    <Skeleton
      variant="rectangular"
      height={51}
      sx={{ mt: 1, mb: 0, borderRadius: 0 }}
      animation="wave"
    />
  ) : (
    <Button
      onClick={props.Handle}
      className={!!props.className ? props.className : classes.Button}
      type="submit"
      fullWidth={!!props.fullWidth ? props.fullWidth : true}
      disabled={props.loading || props.disabled}
      // color={props.color ? props.color : "primary"}
      variant={props.variant ? props.variant : "contained"}
      sx={{
        ...style,
        color: textContrastColor,
        backgroundColor: bgColor, // Define a cor de fundo usando backgroundColor
      }}    >

      {props.isLoading ? (
        <CircularProgress
          color={props.color ? props.color : "primary"}
          size={30}
        />
      ) : (
        props.texto ?? props.children
      )}
    </Button>
  );
}
