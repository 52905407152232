import { FormControl, TextField } from '@mui/material';
import React from 'react';
import Styles from './styles'


const CampoTipoNumerico = ({ size, variant, required, value, onChange, autoFocus = false, label }, props) => {
      const classStyle = Styles();
      return (
            <>
                  <FormControl
                        variant="outlined"
                        sx={{ width: "100% !important", display: "flex" }}
                        index={props.index}
                        size={size}
                        fullWidth={true}
                  >
                        <TextField
                              {...props}
                              size={size}
                              variant={variant}
                              required={required}
                              value={value}
                              label={label}
                              type="number"
                              onChange={onChange}
                              autoFocus={autoFocus}
                        />
                  </FormControl>
            </>
      );
}

export default CampoTipoNumerico