import React, { useState, useRef, useEffect } from "react";
import CampoTexto from "../Campos/CampoTexto";
import { Hidden, InputAdornment, TextField, Typography } from "@mui/material";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';import PersonIcon from "@mui/icons-material/Person";
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//import useStyle from "./styles";
import useStyle from "./styles";
import CampoTipoNumerico from "../Campos/CampoTipoNumerico";
import { ClassNames } from "@emotion/react";

const initPassageiro = {
  idade: 0,
};

function PassageiroList({ onChange }) {
  const paperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (paperRef.current && !paperRef.current.contains(event.target)) {
        setShowButtons(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const cssStyle = useStyle();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [showButtons, setShowButtons] = useState(false);

  const tarefas = ["1", "2", "3", "4", "5", "6", "7"];
  const [quantidadePassageiro, setQuantidadePassageiro] = useState(0);
  const [passageiros, setPassageiros] = useState([]);
  const [showSeletor, setShowSeletor] = useState(false);

  const add = () => {
    const p = passageiros;
    if (p.length < 7) {
      p.push(initPassageiro);
    }

    setPassageiros(p);
    setQuantidadePassageiro(p.length);
  };

  const remove = () => {
    const p = passageiros;

    if (p.length > 0) {
      p.pop();
    }

    setPassageiros(p);
    setQuantidadePassageiro(p.length);
  };

  const handlerChangePassageiro = (i, v) => {
    const passageirosNew = passageiros.map((p, index) => {
      if (i === index) {
        return {
          idade: v.target.value,
        };
      }

      return p;
    });

    setPassageiros(passageirosNew);
    onChange(passageirosNew);
  };

  return (
    <>
      {/* {showSeletor && ( */}
      <div className={cssStyle.containerSelecao}>
        <div className={cssStyle.boxSelecao}>
          <div  className={cssStyle.boxInformativo}>
            <TextField
              value={quantidadePassageiro}
              fullWidth={true}
              id="outlined-start-adornment"
              sx={{ mt: 0.5, cursor: "pointer" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon></PersonIcon>
                  </InputAdornment>
                ),
              }}
              onFocus={() => setShowButtons(true)} // Mostra os botões quando o TextField é clicado
             // Esconde os botões quando o TextField perde o foco
            />
          </div>
        </div>

        {showButtons && (
         
          
              <div  ref={paperRef}>

                <Paper className={cssStyle.Paper}>
                  <Hidden smUp >
                <IconButton
                style={{ position: "absolute", top: 6, left: 0, marginBottom:25 }}
                onClick={() => setShowButtons(false)}
              >
                <ArrowBackIcon /><Typography variant="h6" sx={{pl:2}}>Aplicar</Typography>
              </IconButton>
              </Hidden>
                <Box
                className={cssStyle.Box}
                    sx={{
                     
                    }}
                  >
                    <Typography>Quantidade de passageiros</Typography>
                    <RemoveCircleRoundedIcon
                      onClick={remove}
                      sx={{ fontSize: 22 }}
                      className={cssStyle.button}
                    />
                    <Typography
                      style={{ fontSize: 18 }}
                      className={cssStyle.literalQuantidade}
                    >
                      {quantidadePassageiro}
                    </Typography>
                    <AddCircleRoundedIcon
                      sx={{ fontSize: 22 }}
                      onClick={add}
                      className={cssStyle.button}
                    />
                  </Box>

                  <Grid
                    container
                    xs={12}
                    sx={{ flexDirection: "column", alignItems: "center" }}
                  >
               
                  </Grid>
                    <Grid xs={12} className={cssStyle.Item} item>
                        {passageiros &&
                          passageiros.map((p, i) => {
                            return (
                              <>
                                <Grid
                                  xs={12}
                                  lg={12}
                                  className={cssStyle.Campo}
                                  item
                                >
                                  <CampoTipoNumerico
                                    value={`${p.idade}`}
                                    label="Idade do passageiro"
                                    size="small"
                                    name={`passageiro-${i}`}
                                    enable={true}
                                    onChange={(value) =>
                                      handlerChangePassageiro(i, value)
                                    }
                                    key={i}
                                    className={cssStyle.campoIdade}
                                    fullWidth={true}
                                  />
                                </Grid>
                              </>
                            );
                          })}
                      </Grid>
    </Paper>
      
              </div>
           
       
        )}
      </div>
      {/* )} */}
    </>
  );
}

export default PassageiroList;
