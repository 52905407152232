import * as React from "react";
import { useQuery } from "react-query";
import useAlert from "../../../../hooks/useAlert";
import { VITRINERECEBIMENTO } from "../../../../util/typesReactQuery";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import {
  getValoresItemCarrinho,
  getVitrineRecebimento,
} from "../../../../api/Vitrine";
import { Style } from "./styles";

import { useAuth } from "../../../../hooks/AuthContext";
import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  Paper,
  Skeleton,
  Hidden,
  styled,
} from "@mui/material";
import SeletorMoeda from "../../../Campos/SeletorMoeda";
import Tarifa from "../../Tarifa";
import Subtitulo from "../../../Textos/Subtitulo";
import {
  OrdemPagamento,
  CanalBancarioExterior,
  MultSkeleton,
  Titulo,
} from "../../../../components";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  boxShadow: "none",
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const VitrineRecebimento = ({ setSelectedOrdem }) => {
  const { Alert, showAlertError } = useAlert();
  const { user } = useAuth();
  const [vitrineRecebimento, setVitrineRecebimento] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [itemVitrineSelected, setItemVitrineSelected] = useState(null);
  const [impostos, setImpostos] = useState(null);
  const [isError, setIsError] = useState(false);

  useQuery(
    [VITRINERECEBIMENTO],
    () => getVitrineRecebimento(user.id),
    {
      onSuccess: (data) => {
        if (!!!data) {
          setIsError(true);
          showAlertError(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não Foi encontrado nenhuma vitrine de Recebimento - RC0B7`
          );
          return;
        }
        if (!!data?.errorMessage) {
          setIsError(true);
          showAlertError(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data.errorMessage} - R2D4E`
          );
          return;
        }

        setVitrineRecebimento((prev) => data?.data);
        setItemVitrineSelected((prev) => data?.data.itensVitrine[0]);
      },
      onError: (error) => {
        setIsError(true);
        showAlertError(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - R3403`
        );
      },
      refetchOnWindowFocus: false,
    }
  );



  const { refetch: refetchImpostos } = useQuery(
    [],
    () =>
      getValoresItemCarrinho(
        vitrineRecebimento?.id ?? 0,
        itemVitrineSelected?.id ?? 0,
        vitrineRecebimento?.idfinalidade ?? 0,
        user?.idHotsite ?? 0
      ),
    {
      onSuccess: (data) => {
        if (!!data?.errorMessage) {
          setIsError(true);
          console.error(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data?.errorMessage} - R73BE`
          );
          return;
        }

        setImpostos((prev) => ({ ...data?.data }));
      },
      onError: (error) => {
        setIsError(true);
        console.error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - RD137`
        );
      },

      refetchOnWindowFocus: false,
      refetchInterval: 5000,
    }
  );
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlerChangeItem = (event) => {
    setItemVitrineSelected((prev) => ({
      ...vitrineRecebimento.itensVitrine.find(
        (i) => i.id === event.target.value
      ),
    }));
  };

  useEffect(() => {
    if (!!vitrineRecebimento?.itensVitrine?.length && impostos && !isError)
      setIsLoading(false);

    if (isError) setIsLoading(false);
  }, [vitrineRecebimento?.itensVitrine, impostos, isError]);
  const classes = Style();

  return (
    <>
      <Grid
        container
        xs={12}
        className={classes.Grid}
        md={12}
        sm={12}
        lg={12}
        xl={12}
      >
        <Hidden smUp>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Receber" {...a11yProps(0)} />
                <Tab label="Ver minhas ordens" {...a11yProps(1)} />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <Grid
                className={classes.Operar}
                item
                xs={12}
                md={12}
                lg={5}
                sx={{ mt: 0 }}
              >
                <Titulo sx={{ fontSize: 20, fontWeight: "bold", mt: 2, p: 1 }}>
                  Recebimento do exterior
                </Titulo>
                <Subtitulo sx={{ mt: 2, mb: 1, p: 1 }}>
                  Selecione a moeda que deseja receber e envie os dados bancários
                  para quem irá realizar a transferência
                </Subtitulo>
                <Item sx={{ display: "flex", mb: 4, alignItems: "center", p: 1 }}>
                  {vitrineRecebimento?.itensVitrine.length > 0 &&
                    itemVitrineSelected?.id > 0 && (
                      <FormControl className={classes.FormControl}>
                        <SeletorMoeda
                          list={vitrineRecebimento?.itensVitrine}
                          value={itemVitrineSelected?.id}
                          setSelect={handlerChangeItem}
                          size="normal"
                          fullWidth={true}
                        />
                      </FormControl>
                    )}
                  <Item sx={{ width: "95%" }}>
                    <Tarifa
                      casasDecimais={
                        itemVitrineSelected?.moeda?.numeroCasaDecimais
                      }
                      tarifaAdministrativa={false}
                      // impostos={impostos?.impostos}
                      simbolo={itemVitrineSelected?.moeda?.simbolo}
                      taxa={impostos?.valorTaxa}
                    // isIofRecebimento={true}
                    />
                  </Item>
                </Item>
                <Item sx={{ display: "flex", mb: 4, alignItems: "center", p: 1 }}>
                  <CanalBancarioExterior
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    itemVitrine={itemVitrineSelected}
                  />
                </Item>

              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid
                className={classes.Box}
                sx={{ background: "#fcfcfc" }}
                item
                xs={12}
                md={12}
                lg={4}
              >
                <Item sx={{ pt: 2, pb: 10, pl: 2, background: "transparent" }}>
                  <OrdemPagamento
                    handlerSelectOrdem={setSelectedOrdem}
                    isLoading={isLoading}
                    itensVitrine={vitrineRecebimento?.itensVitrine}
                  />
                </Item>
              </Grid>{" "}
            </TabPanel>
          </Box>
        </Hidden>
        {isLoading && (
          <>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={12} md={12} lg={5}>
                <Skeleton
                  sx={{ m: 3 }}
                  variant="rectangular"
                  animation="wave"
                  height={250}
                  width={"90%"}
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={250}
                  sx={{ m: 3 }}
                  width={"90%"}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={5}>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height={520}
                  sx={{ m: 3 }}
                  width={"90%"}
                />
              </Grid>
            </Grid>
          </>
        )}

        <Hidden smDown>
          {!isLoading && (
            <>
              <Grid
                className={classes.Operar}
                item
                xs={12}
                md={12}
                lg={5}
                sx={{ mt: 0 }}
              >
                <Titulo sx={{ fontSize: 20, fontWeight: "bold", mt: 2 }}>
                  Recebimento do exterior
                </Titulo>
                <Subtitulo sx={{ mt: 2, mb: 1 }}>
                  Selecione a moeda que deseja receber e envie os dados bancários
                  para quem irá realizar a transferência
                </Subtitulo>
                <Item sx={{ display: "flex", mb: 4, alignItems: "center" }}>
                  {vitrineRecebimento?.itensVitrine.length > 0 &&
                    itemVitrineSelected?.id > 0 && (
                      <FormControl className={classes.FormControl}>
                        <SeletorMoeda
                          list={vitrineRecebimento?.itensVitrine}
                          value={itemVitrineSelected?.id}
                          setSelect={handlerChangeItem}
                          size="normal"
                          fullWidth={true}
                        />
                      </FormControl>
                    )}
                  <Item sx={{ width: "95%" }}>
                    <Tarifa
                      casasDecimais={
                        itemVitrineSelected?.moeda?.numeroCasaDecimais
                      }
                      // tarifaAdministrativa={impostos?.tarifaAdministrativa}
                      // impostos={impostos?.impostos}
                      simbolo={itemVitrineSelected?.moeda?.simbolo}
                      taxa={impostos?.valorTaxa}
                      isIofRecebimento={true}
                    />
                  </Item>
                </Item>

                <CanalBancarioExterior
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  itemVitrine={itemVitrineSelected}
                />
              </Grid>
              <Grid lg={1}></Grid>
              <Grid
                className={classes.Box}
                sx={{}}
                item
                xs={12}
                md={12}
                lg={4}
              >
                <Item sx={{ pt: 2, pb: 10, pl: 2, background: "transparent", background: "#fcfcfc" }}>
                  <OrdemPagamento
                    handlerSelectOrdem={setSelectedOrdem}
                    isLoading={isLoading}
                    itensVitrine={vitrineRecebimento?.itensVitrine}
                  />
                </Item>
              </Grid>
            </>
          )}
        </Hidden>
        {isError && (
          <Subtitulo>
            Não foi possivel encontrar nenhum dado de vitrine e nem de ordem de
            pagamento
          </Subtitulo>
        )}
      </Grid>
    </>
  );
};

export default VitrineRecebimento;
