import React from 'react';
import { BreadCrumbsPaginas, MenuDashboard } from '../../components';
import DadosCorretora from '../../components/Contato'
import { Box } from '@mui/material';



export default function Contato() {
      return (
            <>
                  <MenuDashboard>
                        <Box
                              sx={{
                                    mt: 3,
                                    pb: 2,
                                    width: "100%",
                                    height: "auto",
                                    background: "#fff",
                              }}
                        >
                              <DadosCorretora />
                        </Box>
                  </MenuDashboard>
            </>
      );
}