import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  Grid: {
    padding: "0.5rem",
    display: "block",
    [theme.breakpoints.up("sm")]: {
      padding: "2rem",
      display: "flex",
    },
   
  },
  Card: {
    padding: 12,
   
    borderRadius: 15,
    margin: 10,
    backgroundColor:'#f5f5f5',
  },
}));

export default useStyles;
