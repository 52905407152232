import { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Skeleton from "@mui/material/Skeleton";

export default function Seletor({
  addEmpty = false,
  label,
  size,
  fullWidth,
  variant,
  initial,
  value,
  list,
  setSelect,
  disabled,
  sx,
  isLoading = false,
  defaultValue = 0,
  key,
  textoOpcaoPadrao = "Escolha uma opção"
}) {
  const [open, setOpen] = useState(false);

  const labelName = `select-${label}`;
  const idName = `id-${label}`;

  const handleChange = (event) => {
    setSelect(event.target.value);
  };

  const handleShow = (isShow) => {
    setOpen(isShow);
  };

  return (
    <>
      <FormControl variant={variant} size="large" sx={sx}
      fullWidth={fullWidth}
      >
        {isLoading && (
          <Skeleton
            variant="wave"
            height={40}
            key={key}
       
            animation={false}
          />
        )}
        {!isLoading && (
          <>
            <InputLabel variant={variant} id={labelName} size={size ?? "large"}>
              {label}
            </InputLabel>
            <Select
           variant={variant}
              defaultValue={defaultValue}
              disabled={disabled}
              labelId={labelName}
              id={idName}
              label={label}
              open={open}
              onClose={() => handleShow(false)}
              onOpen={() => handleShow(true)}
              value={value}
              size={size ?? "large"}
              onChange={handleChange}
              fullWidth={fullWidth}
            >
              {addEmpty && <MenuItem value={initial ?? 0}>{textoOpcaoPadrao}</MenuItem>}
              {list &&
                list.map((row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row.descricao}
                  </MenuItem>
                ))}
            </Select>
          </>
        )}
      </FormControl>
    </>
  );
}
