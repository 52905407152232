import { useEffect, useState, useCallback } from "react";
import CarrinhoPage from "../../../../components/CarrinhoPage";
import { useAuth } from "../../../../hooks/AuthContext";
import { useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import StoreIcon from "@mui/icons-material/Store";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

import Button from "@mui/material/Button";

import RetiradaLoja from "./RetiradaLoja";
import Delivery from "./Delivery";

import { useNavigate, useParams } from "react-router-dom";
import useAlert from "../../../../hooks/useAlert";
import { addEntrega, getEntrega } from "../../../../api/Carrinho/Turismo";
import { Alert, List, ListItemText, Skeleton, Typography } from "@mui/material";
import { saveLocalStorageTimerCarrinho } from "../../../../util/LocalStorage";
import {
  Timer,
  ClienteEndereco,
  ButtonVoltar,
  Titulo,
} from "../../../../components";
import TiposPedido from "../../../../util/typesTipoPedido";
import { useQuery } from "react-query";
import { GETVALORSEDEX } from "../../../../util/typesReactQuery";
import { getValorSedex } from "../../../../api/Endereco";
import {
  addEntrega as addEntregaOuro,
  getEntregaOuro,
} from "../../../../api/Carrinho/Ouro";
import { formatMoneyInString } from "../../../../util/helpers";
import useStyle from "./style";
import useLojaFechada from "../../../../hooks/useLojaFechada";
import usePedido from "../../../../hooks/usePedido";
// import { Container } from './styles';

export default function EntregaTurismo() {
  const { Alert: AlertCustom, showAlertError, showAlertSuccess, showAlertInfo } = useAlert();
  const navigation = useNavigate();

  const { lojaFechada, errors: errorLojaFechada } = useLojaFechada();

  const classes = useStyle();
  const [searchParams] = useSearchParams();
  const [isRemocao, setIsRemocao] = useState(
    searchParams.get("remocao") !== null
  );

  const { isOuro: isGold } = useParams();

  const [isOuro, setIsOuro] = useState(isGold ?? false);
  const [tipoEntregaSelecionado, setTipoEntregaSelecionado] = useState(null);
  const [pontoRetiradaItem, setPontoRetiradaItem] = useState(null);
  const [regiaoEntregaItem, setRegiaoEntregaItem] = useState(null);
  const [sedex, setSedex] = useState({ cep: "", valorFrete: "" });
  const [enderecoItem, setEnderecoItem] = useState(null);
  const [valorFrete, setValorFrete] = useState(0);
  const { user } = useAuth();
  const [moduloEntrega, setModuloEntrega] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [urlRetorno, setUrlRetorno] = useState("");
  const [isSelectedEndereco, setIsSelectedEndereco] = useState(false);
  const [habilitaButtonEndereco, setHabilitaButtonEndereco] = useState(false);
  const { itensPedido } = usePedido();

  const [steps, setSteps] = useState([
    {
      label: "Vitrine",
      description: "",
    },
    {
      label: "Tipo de Entrega",
      description: "",
    },
    {
      label: "Forma de pagamento",
      description: ``,
    },

    {
      label: "Finalizar",
      description: ``,
    },
  ]);

  useEffect(() => {
    if (moduloEntrega?.idTipoPedido === TiposPedido.CompraEspecie) {
      if (
        !!itensPedido &&
        itensPedido.itens.every((item) => item.moeda.isEspecie === false)
      )
        navigation(
          `/carrinho/turismo/pagamento/${moduloEntrega?.idTipoPedido}/${moduloEntrega?.isRecarga}`
        );
    }
  }, [itensPedido]);

  useEffect(() => {
    if (errorLojaFechada) {
      showAlertError(errorLojaFechada);
      return;
    }

    if (lojaFechada && lojaFechada.isFechada) navigation("/LojaFechada");
  }, [lojaFechada, errorLojaFechada]);




  const init = async (idCliente) => {
    if (isRemocao)
      showAlertSuccess(
        "As opções de entrega mudam de acordo com os itens no carrinho, por isso, pedimos que selecione novamente"
      );

    let response;

    let isOuro = itensPedido?.itens[0]?.moeda?.isOuro;

    if (isOuro) {
      response = await getEntregaOuro(idCliente);
    } else
      response = await getEntrega(idCliente);


    if (!response?.data && response?.data?.tiposEntregas.length === 0) {
      showAlertError(
        "Ops, algo deu errado: Não foi encontrada nenhuma opção de entrega  -  RAC23"
      );
      return;
    }



    if (response?.data?.dataExpiracao)
      saveLocalStorageTimerCarrinho(response.data.dataExpiracao);

    if (response?.data?.isRecarga)
      navigation(
        `/carrinho/turismo/pagamento/${response.data.idTipoPedido}/${response.data.isRecarga}`
      );

    configURLRetorno(response.data);

    setModuloEntrega(response.data);
  };

  const handlerChangeTipoEntrega = useCallback(
    (event, tipo) => {
      setRegiaoEntregaItem(null);
      setPontoRetiradaItem(null);
      setEnderecoItem(null);
      setTipoEntregaSelecionado(tipo);
      if (tipo === "Sedex") setIsSelectedEndereco(true);
    },
    [tipoEntregaSelecionado]
  );

  function configURLRetorno(obj) {
    if (isOuro) {
      setUrlRetorno("/carrinho/Compra-ouro/vitrine");
      return;
    }

    if (obj?.idTipoPedido == TiposPedido.VendaEspecie) {
      if (obj.isEspecie) {
        setUrlRetorno("/carrinho/turismo-compra/especie/vitrine");
        return;
      }

      if (obj.isRecarga) {
        setUrlRetorno("/carrinho/turismo-compra/cartao-recarga/vitrine");
        return;
      }
      setUrlRetorno("/carrinho/turismo-compra/cartao/vitrine");
      return;
    }

    if (obj?.idTipoPedido == TiposPedido.CompraEspecie) {
      if (obj.isEspecie) {
        setUrlRetorno("/carrinho/turismo-venda/especie/vitrine");
        return;
      }
      setUrlRetorno("/carrinho/turismo-venda/cartao-saldo/vitrine");
      return;
    }
  }

  const statusIcon = (status) => {
    switch (status) {
      case "Store":
        return <StoreIcon />;
      case "DeliveryDining":
        return <DeliveryDiningIcon />;
      default:
        return <MailOutlineIcon />;
    }
  };

  useEffect(() => {
    init(user.id);
  }, [itensPedido]);

  useEffect(() => {
    if (regiaoEntregaItem?.id) setHabilitaButtonEndereco(true);
  }, [regiaoEntregaItem]);

  const { refetch: refetchSedex } = useQuery(
    [GETVALORSEDEX],
    () => getValorSedex(sedex.cep, moduloEntrega.idPraca, isOuro, itensPedido?.valorTotalMoedaNacional - itensPedido?.valorFrete - itensPedido?.valorFreteLogistica - itensPedido?.valorFreteSeguro),
    {
      onSuccess: (data) => {
        if (!data || !!data.errorMessage)
          showAlertError(
            `Ops, algo deu errado: Não foi possivel informar o valor da entrega relacionada ao seu CEP - R14A2 `
          );

        if (data?.data?.information?.length > 0)
          showAlertInfo(data.data.information)

        let valorAcrescentado =
          (data.data.valorFrete || 0) +
          (data.data.valorFreteSeguro || 0) +
          (data.data.valorFreteLogistica || 0);

        setSedex({ ...sedex, valorFrete: valorAcrescentado });
      },
      onError: (error) => {
        showAlertError(`Ops, algo deu errado: ${error} - R94CC `);
      },
      onSettled: () => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      },
      enabled: false,
    }
  );

  useEffect(() => {
    if (!!sedex?.cep) {
      setIsLoading(true);
      refetchSedex();
    }
  }, [sedex?.cep]);

  const handleNext = async (event, enderecoRecebido) => {
    event.preventDefault();

    let endereco = enderecoRecebido ?? enderecoItem;

    let timer = 2000;

    setIsLoading(true);
    try {
      if (
        !regiaoEntregaItem &&
        !!!sedex?.cep &&
        !pontoRetiradaItem &&
        !enderecoItem
      ) {
        throw new Error("É necessário selecionar uma forma de entrega!");
      }

      if (isSelectedEndereco && !!!endereco)
        throw new Error("É necessário selecionar um endereço para a entrega");

      let valorFrete = 0;
      let regiaoEntrega = regiaoEntregaItem;

      if (regiaoEntrega?.id > 0 && !!regiaoEntrega?.valorFrete > 0) {
        valorFrete = regiaoEntrega.valorFrete;
        regiaoEntrega.endereco = endereco;
      }

      if (!!sedex?.valorFrete > 0) valorFrete = sedex.valorFrete;

      let regiaoEntregaUtilizada = {
        ...regiaoEntrega,
        endereco: { ...(endereco ?? enderecoItem) },
      };

      let response;

      if (isOuro)
        response = await addEntregaOuro(user.id, {
          pontoRetirada: pontoRetiradaItem,
          endereco: endereco ?? enderecoItem,
          valorFrete: valorFrete,
          regiaoEntrega: regiaoEntregaUtilizada,
        });
      else
        response = await addEntrega(user.id, {
          pontoRetirada: pontoRetiradaItem,
          endereco: endereco ?? enderecoItem,
          valorFrete: valorFrete,
          regiaoEntrega: regiaoEntregaUtilizada,
        });

      timer = 2000;

      if (response && !response.errorMessage) {
        if (isOuro)
          setTimeout(() => {
            navigation(
              `/carrinho/Ouro/pagamento/${moduloEntrega.idTipoPedido}`
            );
          }, 2000);
        else
          setTimeout(() => {
            navigation(
              `/carrinho/turismo/pagamento/${moduloEntrega.idTipoPedido}/${moduloEntrega.isRecarga}`
            );
          }, 2000);
      }
    } catch (error) {
      showAlertError(error.message);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, timer);
    }
  };

  const handlerPontoRetirada = (PontoRetirada) => {
    setPontoRetiradaItem({ ...PontoRetirada });
    setRegiaoEntregaItem(null);
    setSedex(null);
    setEnderecoItem(null);
    setValorFrete(0);
  };

  const handlerRegiaoEntrega = (regiaoEntrega, tipoDelivery) => {
    setPontoRetiradaItem(null);
    setRegiaoEntregaItem({
      ...regiaoEntrega,
      tipoDelivery: { ...tipoDelivery },
      valorFrete: tipoDelivery?.valorFrete
        ? tipoDelivery.valorFrete
        : regiaoEntrega.valorFrete,
    });
    setSedex(null);
    setEnderecoItem(null);
    setValorFrete(0);
  };

  const handlerSedex = (event, endereco) => {
    setPontoRetiradaItem(null);
    setRegiaoEntregaItem(null);
    setEnderecoItem({ ...endereco });
    setSedex({ ...sedex, cep: endereco.cep, id: endereco.id });
    setValorFrete(0);
  };

  const handleBack = () => {
    setIsLoading(true);
    navigation(urlRetorno, {
      replace: false,
    });
  };

  const handleSelectRegiaoNovamente = () => {
    setHabilitaButtonEndereco(false);
    setIsSelectedEndereco(false);
    setRegiaoEntregaItem(null);
    setSedex(null);
  };

  const handlerAtivaEndereco = () => {
    if (tipoEntregaSelecionado !== "Sedex" && !regiaoEntregaItem) {
      showAlertError("É necessario selecionar uma região para entrega");
      return;
    }

    setIsSelectedEndereco(true);
  };

  return (
    <>
      <AlertCustom />
      <CarrinhoPage
        handleBack={handleBack}
        handleNext={
          (tipoEntregaSelecionado === "Delivery" ||
            tipoEntregaSelecionado === "Sedex") &&
            moduloEntrega &&
            !!!enderecoItem
            ? handlerAtivaEndereco
            : handleNext
        }
        steps={steps}
        activeStep={1}
        loadingButton={isLoading}
        disableButtonNext={
          isSelectedEndereco && tipoEntregaSelecionado !== "Sedex"
        }
        disabledVoltarCompras={true}
        enable={
          tipoEntregaSelecionado === "Delivery" && !habilitaButtonEndereco
            ? false
            : true
        }
        handleBackVoltarComprar={handleBack}
      >
        {!!moduloEntrega?.dataExpiracao && (
          <Timer
            active={!!moduloEntrega.dataExpiracao}
            dataExpiracao={moduloEntrega?.dataExpiracao}
            caminhoRedirect={"/CarrinhoVazio"}
          />
        )}
        <Titulo sx={{ mb: 0, mt: 2 }}>Forma de entrega</Titulo>
        <Grid
          container
          sx={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            mt: 2,
            minHeight: "11em",
          }}
        >
          <Grid
            item
            xs
            sx={{
              width: "100%",
              minHeight: "3.5em",
              display: "flex",
              justifyContent: "center",
              gap: "",
              borderBottom: "1px solid #ccc",
              pb: 1,
            }}
          >
            {isLoading || !moduloEntrega || !moduloEntrega?.tiposEntregas ? (
              <>
                <Skeleton width={"50%"} height={50} animation="wave" />
                <Skeleton width={"50%"} height={50} animation="wave" />
              </>
            ) : (
              !isSelectedEndereco &&
              moduloEntrega?.tiposEntregas?.map((e) => (
                <>
                  <Button
                    sx={{ width: "100%" }}
                    variant="outlined"
                    startIcon={statusIcon(e.icone)}
                    onClick={(event) =>
                      handlerChangeTipoEntrega(event, e.codigo)
                    }
                  >
                    {e.codigo === "Retirada" ? "RETIRADA" : e.descricao}
                  </Button>
                </>
              ))
            )}
          </Grid>

          <Grid item sx={{ width: "100%" }}>
            {isLoading ? (
              <>
                <Skeleton
                  width={"100%"}
                  height={200}
                  animation="wave"
                  sx={{ mb: "-2rem", mt: "-3rem" }}
                />
                <Skeleton
                  width={"100%"}
                  height={200}
                  animation="wave"
                  sx={{ mb: "-2rem", mt: "-3rem" }}
                />
              </>
            ) : (
              <>
                {tipoEntregaSelecionado === "Retirada" && moduloEntrega && (
                  <RetiradaLoja
                    idPraca={moduloEntrega.idPraca}
                    onChange={handlerPontoRetirada}
                  />
                )}
                {tipoEntregaSelecionado === "Delivery" &&
                  moduloEntrega &&
                  !isSelectedEndereco && (
                    <Delivery
                      idPraca={moduloEntrega.idPraca}
                      isEspecie={moduloEntrega.isEspecie}
                      isVenda={moduloEntrega.isVenda}
                      onChange={handlerRegiaoEntrega}
                    />
                  )}

                {/* {tipoEntregaSelecionado === "Sedex" && moduloEntrega && !isSelectedEndereco && (
                  <Sedex
                  />
                )} */}

                {isSelectedEndereco && (
                  <>
                    <Grid container sx={{ alignItems: "center" }}>
                      <ButtonVoltar
                        sx={{ ml: -2, mt: 1 }}
                        handleVoltar={handleSelectRegiaoNovamente}
                      ></ButtonVoltar>
                    </Grid>
                    <Typography
                      color="text.secondary"
                      style={{ fontSize: "1.3rem" }}
                    >
                      Selecione ou adicione o endereço de destino
                    </Typography>
                    <List
                      dense
                      className={classes.List}
                      sx={{
                        width: "100%",
                        maxWidth: "100%",
                        overflow: "auto",
                        overflowX: "hidden",
                        paddingRight: 0,
                        marginTop: 2,
                        bgcolor: "background.paper",
                      }}
                    >
                      <ClienteEndereco
                        idCliente={user.id}
                        isOperacao={true}
                        handlerNext={handleNext}
                        isSedex={tipoEntregaSelecionado === "Sedex"}
                        regiaoEntregaSelecionada={regiaoEntregaItem}
                        handlerChangeSedex={handlerSedex}
                        enderecoItemSelecionado={enderecoItem}
                        sedexSelecionado={sedex}
                      />
                    </List>

                    {!!sedex?.valorFrete && (
                      <List sx={{ flexBasis: "25%" }}>
                        <ListItemText
                          primary={
                            <Alert severity="info">
                              {" "}
                              Valor da sua entrega é:{" "}
                              {formatMoneyInString(sedex.valorFrete)}
                            </Alert>
                          }
                        />
                      </List>
                    )}

                    {!!regiaoEntregaItem?.valorFrete && (
                      <List sx={{ flexBasis: "25%" }}>
                        <ListItemText
                          primary={
                            <Alert severity="info">
                              {" "}
                              Valor da sua entrega é:{" "}
                              {formatMoneyInString(
                                regiaoEntregaItem.valorFrete
                              )}
                            </Alert>
                          }
                        />
                      </List>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </CarrinhoPage>
    </>
  );
}
