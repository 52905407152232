import { useState } from "react";
import { getNotificacoesNaoLidasCliente, setNotificacaoClientelido, setMarcartodasComoLida } from "../../../api/Notificacao";
import { useInfiniteQuery, useMutation } from "react-query";
import { useAuth } from "../../AuthContext";


export default function useNotificationsNaoLidas(isNotificacaoLida) {
    const { user } = useAuth();
    const [notificacoes, setNotificacoes] = useState();
    const [errorNotNaoLida, seterrorNotNaoLida] = useState("");

    const setMarcarComoLida = async (idNotificacao) => {
        try {
            let data = await setNotificacaoClientelido(user?.id, idNotificacao);

            if (!!data?.data?.errorMessage)
                throw new Error(data.data.errorMessage);

            refetchNotificacoesNaoLidas();

        } catch (error) {
            seterrorNotNaoLida(error.message + " RHD9I")
        }
    }

    const {
        isLoading,
        refetch: refetchNotificacoesNaoLidas,
        hasNextPage: hasNextPageNaoLidas,
        fetchNextPage: handlerNextPageNaoLidas,
    } = useInfiniteQuery(
        "",
        ({ pageParam = 1 }) =>
            getNotificacoesNaoLidasCliente(user?.id, pageParam),
        {
            getNextPageParam: (lastPage, allPages, data) => {
                if (lastPage?.data?.length === 5) {
                    return allPages.length + 1;
                }
                return undefined;
            },
            onSuccess: (data) => {
                if (data?.pages) {
                    let dadosNotificacao = [];
                    data?.pages.forEach((page) => {
                        page.data.forEach(notification => dadosNotificacao.push(notification));
                    })

                    setNotificacoes(() => dadosNotificacao);
                }
            },
            onError: (error) => {
                seterrorNotNaoLida(error.message + " RE0KT")
            },
            enabled: false,
            retry: false,
            refetchOnWindowFocus: false,
        }
    );


    const mutation = useMutation(setMarcartodasComoLida, {
        //função chamada quando concluido a chamada da api
        onSuccess: (data) => {
            if (!!data?.errorMessage)
                throw new Error(data.errorMessage, " - RVOVS");

            refetchNotificacoesNaoLidas();
        },
        //função chamada quando ocorre um erro
        onError: async (err, variables, context) => {
            console.error(err);
            seterrorNotNaoLida(err.message + " RDF2G")
        },

    });


    return {
        notificacoesNaoLidas: notificacoes,
        hasNextPageNaoLidas,
        quantidadeNotificacoes: notificacoes?.length ?? 0,
        refetchNotificacoesNaoLidas,
        handlerNextPageNaoLidas,
        setMarcarComoLida,
        isLoadingNotNaoLida: isLoading,
        errorNotNaoLida,
        setTodasComoLida: () => mutation.mutate(user?.id)

    }

}