import { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  FormControlLabel,
  Checkbox,
  FormGroup,
  Box,
  Grid,
  Typography,
  Button

} from "@mui/material";

import { ButtonCustom } from "../../components/Botoes/Button";

import { ButtonAcaoGrande, LogoEmpresa } from "../../components";
import Titulo from "../../components/Textos/Titulo";
import Subtitulo from "../../components/Textos/Subtitulo";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
// import Logo from "../../assets/images/logo.png";
import StepLinear from "../../components/Steps/Step";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Hidden from "@mui/material/Hidden";
import { Termo } from "../../components";
import { getTermoUsoCadastro } from "../../api/TermoUso";
import { getTiposCadastroPermitidos } from "../../api/chamadasApi";
import { registrarPreCadastro } from "../../api/Cadastro";
import { Link } from "react-router-dom";
import useAlert from "../../hooks/useAlert";

import useStyles from "./styles";
import {
  CampoPassword,
  CampoDocumentoIdentificacao,
  CampoEmail,
  BoxFinalizacao,
  Copyright,
  EspacoMenuLateralEsquerdo,
  EspacoConteudoLateralDireito,
} from "../../components";

const steps = [
  {
    label: "Tipo de conta",
    description: ``,
  },
  {
    label: "Criar conta",
    description: "",
  },
  {
    label: "Verificação de e-mail",
    description: ``,
  },
];
const stepsMobile = [
  {
    label: "",
    description: ``,
  },
  {
    label: "",
    description: "",
  },
  {
    label: "",
    description: ``,
  },
];

export default function Cadastro() {
  const preClienteDefault = {
    email: "",
    senha: "",
    numeroDocumentoIdentificacao: "",
    idTipoCliente: 0,
  };
  const [emailBase, setEmailBase] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [preCliente, setPreCliente] = useState(preClienteDefault);
  const [loading, setLoading] = useState(false);
  const [termoUsoAceito, setTermoUsoAceito] = useState(false);
  const [termoUsoText, setTermoUsoText] = useState("");
  const [tiposCadastroPermitidos, setTiposCadastroPermitidos] = useState({});
  const [helperTextError, setHelperTextError] = useState("")
  const [disabledButton, setDisabledButton] = useState(false);
  const { Alert, showAlertError } = useAlert();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const responseTermo = await getTermoUsoCadastro();
    setTermoUsoText(responseTermo);
    const responseTipos = await getTiposCadastroPermitidos();
    setTiposCadastroPermitidos(responseTipos);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // const data = new FormData(event.currentTarget);
  };

  const handleChange = (e) => {
    if (e.target.name.toLowerCase() === "email") {

      if (e.target.value !== emailBase) {
        setHelperTextError("Os e-mails informados são diferentes")
        setDisabledButton(true)
      }
      else {
        setHelperTextError("")
      }

    }


    setPreCliente({ ...preCliente, [e.target.name]: e.target.value });
  };

  const handlerChangeEmailBase = (e) => {
    setEmailBase(e.target.value);
  };

  const handleNext = (idTipoCliente) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setPreCliente({
      ...preCliente,
      idTipoCliente,
    });
  };


  useEffect(() => {
    if ((!!preCliente.email && !!emailBase) && (preCliente.email === emailBase))
      if (!!preCliente.numeroDocumentoIdentificacao)
        if (!!preCliente.senha) {
          setDisabledButton(false)
          return;
        }

    setDisabledButton(true)

  }, [preCliente])


  const handlerAutenticate = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      if (!termoUsoAceito) {
        showAlertError("É necessário aceitar os termos de uso!");
        setLoading(false);
      } else {
        // const response = await api.post("/cadastro/Registrar", preCliente);
        const response = await registrarPreCadastro(preCliente);

        if (response.errorMessage !== "" && response.errorMessage !== null) {
          showAlertError(response.errorMessage);
          setLoading(false);
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setPreCliente(preClienteDefault);
          setLoading(false);
        }
      }
    } catch (error) {
      showAlertError(error.message);
      setLoading(false);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setPreCliente({
      email: "",
      senha: "",
      numeroDocumentoIdentificacao: "",
      idTipoCliente: 0,
    });
  };

  const handleAgain = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 2);
    setPreCliente({
      email: "",
      senha: "",
      numeroDocumentoIdentificacao: "",
      idTipoCliente: 0,
    });
  };

  const classes = useStyles();
  return (
    <>
      <Alert />
      <Hidden smUp>
        <StepLinear
          sx={{ p: 15 }}
          orientation="horizontal"
          steps={stepsMobile}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </Hidden>
      <CssBaseline />

      <Grid container component="main" className={classes.Container}>
        <Hidden smDown>
          <EspacoMenuLateralEsquerdo>
            <Grid
              item
              md={2}
              xs={false}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LogoEmpresa idHotsite={0} />
            </Grid>

            {/* <Grid
              item
              md={2}
              xs={false}
              sx={{
                backgroundImage: `url(${Logo})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            /> */}

            <Grid
              item
              md={1}
              sm={3}
              xs={false}
              sx={{
                mx: 0,
              }}
              style={{ margin: "auto" }}
            >
              {" "}
              <StepLinear
                orientation="vertical"
                steps={steps}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
            </Grid>

            <Grid
              item
              md={1}
              sm={1}
              xs={false}
              sx={{
                mx: 5,
              }}
            >
              <Copyright />
            </Grid>
          </EspacoMenuLateralEsquerdo>
        </Hidden>
        {/* MENU DIREITO */}
        <EspacoConteudoLateralDireito>
          {activeStep === 0 && (
            <>
              <Grid item className={classes.TipoConta} lg={6}>
                <Hidden smUp>
                  <Grid
                    item
                    md={12}
                    xs={false}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LogoEmpresa idHotsite={0} />
                  </Grid>
                  {/* <Grid
                    item
                    xs={12}
                    alignItems="center"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginRight: "20px",
                      height: "15px",
                      marginTop: 3,
                    }}
                    justifyContent="center"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      component="img"
                      sx={{
                        my: 4,
                        backgroundColor: "none",
                        alignItems: "center",
                      }}
                      alt="Logo"
                      src={Logo}
                    />
                  </Grid> */}
                </Hidden>
                <Box
                  sx={{
                    my: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Grid item className={classes.TipoContaBloco}>
                    <Titulo sx={{ mb: 2 }} label="Olá"></Titulo>
                    <Subtitulo>
                      Escolha o tipo de conta que deseja criar ou faça o
                      <Link className={classes.Link} to="/Login">
                        Login
                      </Link>
                    </Subtitulo>
                    {tiposCadastroPermitidos &&
                      tiposCadastroPermitidos.permiteCadastroPF && (
                        <ButtonCustom

                          type="submit"


                          onClick={() => handleNext(1)}
                          variant="contained"
                          sx={{ mt: 3, mb: 2, width: "100%", p: 1.5, opacity: 0.8 }}
                        >
                          Para você
                        </ButtonCustom>
                      )}
                    {tiposCadastroPermitidos &&
                      tiposCadastroPermitidos.permiteCadastroPJ && (
                        <ButtonCustom

                          type="submit"


                          onClick={() => handleNext(2)}
                          variant="contained"
                          sx={{ mt: 3, mb: 2, width: "100%", p: 1.5, opacity: 0.8 }}
                        >
                          Para sua empresa
                        </ButtonCustom>
                      )}
                    {tiposCadastroPermitidos &&
                      tiposCadastroPermitidos.permiteCadastroPE && (
                        <ButtonCustom

                          type="submit"

                          onClick={() => handleNext(3)}
                          variant="contained"
                          sx={{ mt: 3, mb: 2, width: "100%", p: 1.5, opacity: 0.8 }}                        >
                          Sou estrangeiro
                        </ButtonCustom>
                      )}
                  </Grid>
                </Box>
              </Grid>
            </>
          )}

          {activeStep === 1 && (
            <>
              <Grid item xs={12} alignItems="center" justifyContent="center">
                {" "}
                <Hidden smUp>
                  <Grid
                    item
                    xs={12}
                    alignItems="center"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginRight: "20px",
                    }}
                    justifyContent="center"
                  >
                    <ArrowBackIosNewIcon
                      sx={{ ml: 4 }}
                      onClick={handleBack}
                      className={classes.Voltar}
                    />
                  </Grid>
                </Hidden>
              </Grid>

              <Grid item className={classes.criar} xs={12}>
                <Grid item className={classes.criar} lg={6}>
                  <Hidden smDown>
                    <ArrowBackIosNewIcon
                      onClick={handleBack}
                      className={classes.Voltar}
                    />
                  </Hidden>
                  <Box
                    sx={{
                      my: 4,
                      mx: 4,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {" "}
                    <Titulo sx={{ mb: 2 }} label="Crie sua conta">
                      Crie sua conta
                    </Titulo>
                    <Typography variant="body1">
                      Já tem conta?
                      <Link
                        className={classes.Link}
                        to="/Login"
                        variant="body1"
                      >
                        Faça seu login
                      </Link>
                    </Typography>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: 1 }}
                    >
                      <CampoDocumentoIdentificacao
                        name="numeroDocumentoIdentificacao"
                        value={preCliente.numeroDocumentoIdentificacao}
                        handlerChange={handleChange}
                        idTipoDocumento={preCliente.idTipoCliente}
                      />

                      <CampoEmail
                        value={emailBase}
                        handlerChange={handlerChangeEmailBase}
                        name="email"
                        label="Digite um e-mail"
                      />

                      <CampoEmail
                        value={preCliente.email}
                        handlerChange={handleChange}
                        name="email"
                        helperText={helperTextError}
                        label="Confirme seu e-mail"
                      />

                      <CampoPassword
                        value={preCliente.senha}
                        handleChange={handleChange}
                        name="senha"
                        label="Crie uma senha"
                        loading={loading}
                      />

                      <Box>
                        <FormGroup style={{ marginTop: 13 }}>
                          <FormControlLabel
                            control={<Checkbox defaultChecked size="small" />}
                            label="Deseja receber novidades?"
                            style={{ color: "#666" }}
                          />
                        </FormGroup>
                        <Termo
                          isAceite={termoUsoAceito}
                          setIsAceite={setTermoUsoAceito}
                          modalText={termoUsoText}
                          titulo="Termos de Uso"
                        >
                          <Typography
                            sx={{ cursor: "pointer" }}
                            color="text.secondary"
                          >
                            Aceitar nossos{" "}
                            <span className={classes.span}>termos de uso</span>
                          </Typography>
                        </Termo>
                      </Box>

                      <ButtonAcaoGrande
                        type="submit"
                        loading={loading}
                        Handle={handlerAutenticate}
                        disabled={disabledButton}
                        texto="Criar Conta"
                      ></ButtonAcaoGrande>

                      {/* <ButtonGoogle /> */}

                      <Grid container>
                        <Grid item xs>
                          <Link to="#" variant="body2"></Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Hidden smUp>
                <Grid
                  item
                  md={1}
                  sm={1}
                  xs={false}
                  sx={{
                    mx: 5,
                  }}
                ></Grid>
              </Hidden>
            </>
          )}

          {activeStep === 2 && (
            <BoxFinalizacao
              icon={
                <MarkEmailReadIcon
                  color="primary"
                  sx={{ mb: 3, fontSize: "45px" }}
                />
              }
              titulo="Confirmação de e-mail"
              message="Você recebeu um e-mail, acesse o link enviado para fazer seu login!"
              handleBack={handleAgain}
            />
          )}
        </EspacoConteudoLateralDireito>
      </Grid>
    </>
  );
}
