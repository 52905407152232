import { IVariable } from "../../interfaces/IVariable";
import { createTheme, Theme } from "@mui/material/styles";

export const variables: IVariable = {
  IdHotsite: 0,
  Loja: "fair",
  UserStorage: "XT5cX0Lg4EeynV8AslcZjQ==",
  UserToken: "L3lesmwcWE6vRxPBlHGqCg==",
  UrlApi: "https://prd-ecommerce-fai-api.primecase.com.br/api",
  UrlLoja:"https://prd-ecommerce-fai-web.primecase.com.br/",
};

export const template: Theme = createTheme({
  palette: {
    primary: {
      main: "#fd590f",
      light: "#FEAC87",
      //lighter: "#FFD6C3",
      dark: "#7F2D08",
    },
    secondary: {
      main: "#000",
    },
    error: {
      main: "#a91c1c",
    },
    text: {
      primary: "#222",
      //light: "#fff",
    },
    warning: {
      main: "#ff9800",
    },
    // white: {
    //     main: "#fff",
    // },
  },

  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
  },
});
