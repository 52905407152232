
import { formatMoneyInStringForm } from '../../util/helpers';
import { Badge, Box } from '@mui/material';
import TiposOperacaoCarrinho from "../../util/typesTipoPedido";


export default function Component({ carrinho, children }) {
      const valorDesconto = () => {
            let idTipoOperacaoCarrinho = carrinho?.itens[0]?.idTipoOperacaoCarrinho;

            switch (idTipoOperacaoCarrinho) {
                  case TiposOperacaoCarrinho.CompraEspecie:
                        return `+${formatMoneyInStringForm(carrinho?.cupom?.valorDesconto, 2).replace(/[+-]/g, "")}`
                  case TiposOperacaoCarrinho.VendaEspecie:
                        return `-${formatMoneyInStringForm(carrinho?.cupom?.valorDesconto, 2).replace(/[+-]/g, "")}`
                  case TiposOperacaoCarrinho.Ouro:
                        return `-${formatMoneyInStringForm(carrinho?.cupom?.valorDesconto, 2).replace(/[+-]/g, "")}`
                  case TiposOperacaoCarrinho.Recebimento:
                        return `+${formatMoneyInStringForm(carrinho?.cupom?.valorDesconto, 2).replace(/[+-]/g, "")}`
                  default:
                        return `-${formatMoneyInStringForm(carrinho?.cupom?.valorDesconto, 2).replace(/[+-]/g, "")}`
            }
      }

      if (carrinho?.cupom?.valorDesconto !== 0 && !!carrinho?.cupom?.valorDesconto) {

            return (
                  <Badge
                        sx={{ right: '1.88rem' }}
                        badgeContent={valorDesconto()}
                        color="success">
                        <Box sx={{ mr: '-1.3rem' }}>
                              {children}
                        </Box>
                  </Badge>
            )
      }


      return <>{children}</>;
}
