import { useNavigate } from "react-router-dom";
import { Subtitulo, Timer, Titulo } from "../../../components";
import CarrinhoPage from "../../../components/CarrinhoPage";
import { useAuth } from "../../../hooks/AuthContext";
import { addPagamento } from "../../../api/Carrinho/TranferenciaInternacional";
import { useEffect, useState } from "react";
import useAlert from "../../../hooks/useAlert";
import useLojaFechada from "../../../hooks/useLojaFechada";
import { getlocalStorageTimerCarrinho } from "../../../util/LocalStorage";
import PassageiroItem from "../../../components/PassageiroItem";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";

export function Passageiros() {
  const navigation = useNavigate();
  const { user } = useAuth();
  const { Alert, showAlertError } = useAlert();
  const [loadingAddPagamento, setLoadingAddPagamento] = useState(false);
  const { errors: errorLojaFechada, lojaFechada } = useLojaFechada();
  const [dataLocal, setDataLocal] = useState(() => getlocalStorageTimerCarrinho());
  const [passageiros, setPassageiros] = useState([
    {
      nome: "",
      sobrenome: "",
      cpf: "",
      idSexo: 1,
      dataNascimento: "2023-01-29",
    },
    {
      nome: "",
      sobrenome: "",
      cpf: "",
      idSexo: 1,
      dataNascimento: "2023-01-29",
    },
  ]);

  const steps = [
    {
      label: "Vitrine",
      description: "",
      link: "/SeguroViagem",
    },
    {
      label: "Passageiros",
      description: "",
      link: "/SeguroViagem/Passageiros",
    },
    {
      label: "Forma de pagamento",
      description: ``,
      link: "/SeguroViagem/Pagamento",
    },
  ];

  useEffect(() => {
    if (errorLojaFechada) {
      console.error("Primecase Error - ", errorLojaFechada)
      showAlertError(errorLojaFechada);
      return;
    }

    if (lojaFechada && lojaFechada.isFechada) navigation("/LojaFechada", false);
  }, [lojaFechada, errorLojaFechada]);

  const handlerNext = async (event, pagamento) => {
    event.preventDefault();
    try {
      setLoadingAddPagamento(true);
      const response = await addPagamento(user.id, pagamento);

      if (!!response.errorMessage)
        throw new Error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${response.errorMessage} - R18AC`
        );

      if (!!!response)
        throw new Error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico:não foi possivel registrar a forma de pagamento selecionada- R0EC8`
        );

      if (response && !response.errorMessage) {
        setTimeout(() => {
          navigation("/SeguroViagem/Pagamento");
        }, 2000);
      }
      setTimeout(() => {
        setLoadingAddPagamento(false);
      }, 2100);
    } catch (error) {
      setLoadingAddPagamento(false);
      showAlertError(`${error} - RB120`);
    }
  };

  const handleBack = () => {
    navigation("/SeguroViagem");
  };

  const handlerChangeDataPassgeiro = () => { };

  return (
    <div>
      <Alert />
      <CarrinhoPage
        handleBack={handleBack}
        disableButtonNext={false}
        activeStep={1}
        steps={steps}
      >
        {dataLocal && (
          <Timer
            active={!!dataLocal}
            dataExpiracao={dataLocal}
            caminhoRedirect={"/SeguroViagem"}
          />
        )}

        <Grid>
          {passageiros &&
            passageiros.map((item, i) => {
              return (
                <Box sx={{ mb: '1rem' }}>
                  <Subtitulo>
                    {i + 1}º Passageiro
                  </Subtitulo>
                  <PassageiroItem
                    key={i}
                    p={item}
                    onChange={handlerChangeDataPassgeiro}
                  />
                </Box>
              );
            })}
        </Grid>
        {/* <Grid>
          <BoxVoucherSeguroViagem />
        </Grid> */}
      </CarrinhoPage>
    </div>
  );
}
