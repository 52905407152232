import api from "../index";
import { configurationVariables } from "../../environment/index";

const idHotsite = parseInt(configurationVariables.IdHotsite);

export const GetDadosCorretora = async () => {
      try {
            const { data: response, status } = await api.get(
                  `Contato/GetContatos?idHotsite=${idHotsite}`
            );

            if (status !== 200) throw new Error(response.errorMessage);

            return {
                  isSuccess: true,
                  errorMessage: response.errorMessage,
                  data: response.data,
                  status,
            };
      } catch (error) {
            return { isSuccess: false, errorMessage: error.errorMessage + " RC381" };
      }
};

