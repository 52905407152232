import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
      IconButton: {
            color: theme.palette.error.main + " !important",
            opacity: .6,
            '&:hover': {
                  opacity: 1,
            }
      }
}));

export default style;