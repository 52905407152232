import { Divider } from "@mui/material"
import { useEffect, useState } from 'react'
import { Subtitulo } from '../..'
import parseHtml from "html-react-parser";
import CardCustom from '../../Card'



const OperacaoBancarioRecebimento = ({ dadosBancarios, isTodosDetalhes }) => {

   const [dadosBancariosDescricao, setdadosBancario] = useState([]);

   useEffect(() => {
      if (dadosBancarios?.dadosBancarios)
         setdadosBancario(dadosBancarios?.dadosBancarios.split("|"));
   }, [dadosBancarios])

   return (
      <>
         <CardCustom >

            <Subtitulo sx={{ color: "#000", fontWeight: 'BOLD' }}>💵 Segue os dados de onde deseja receber o seu dinheiro</Subtitulo>
            <hr />

            {!!dadosBancariosDescricao.length ?
               <>
                  {dadosBancariosDescricao.map(d => (
                     <Subtitulo sx={{ fontWeight: 'bold', mb: 2, color: "#000" }}>{d.replace(":", ": ")}</Subtitulo>
                  ))}
               </>
               :
               <>
                  <Subtitulo style={{ color: "#fff !important" }}>  Nenhum dado bancario encontrado</Subtitulo>
               </>
            }
         </CardCustom>
      </>
   )
}

export default OperacaoBancarioRecebimento;