import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { GetDadosCorretora } from "../../api/DadosCorretora";
import { GETDADOSCORRETORA } from "../../util/typesReactQuery";
import useAlert from "../../hooks/useAlert";
import { Box, Grid } from "@mui/material";
import Subtitulo from "../Textos/Subtitulo";
import Titulo from "../Textos/Titulo";
import useStyles from "./style";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import ApartmentIcon from '@mui/icons-material/Apartment';


export default function Component() {
  const [dadosCorretora, setDadosCorretora] = useState();
  const { Alert, showAlertError } = useAlert();
  const classes = useStyles();

  useQuery(`GETDADOSCORRETORA`, () => GetDadosCorretora(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (!data || !data?.data) {
        showAlertError(
          "Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: Nenhuma forma de pagamento encontrada - RXKP1 "
        );
        return;
      }

      if (data.errorMessage) {
        showAlertError(
          `Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: ${data.errorMessage} - RG7ZB `
        );
        return;
      }

      if (data.status) setDadosCorretora(data.data);
    },
    onError: (error) => {
      showAlertError(
        `Ops, ocorreu um erro, informe ao suporte tecnico: ${error?.message} -  RI1K8`
      );
    },
  });

  return (
    <>
      <Alert />
      <Grid lg={10}   className={classes.Grid}>
      
        <Grid
          lg={4}
          md={12}
         className={classes.Card}
        >
            <Box sx={{m:'auto', textAlign:'center'}}>  <ApartmentIcon sx={{m:'auto', textAlign:'center'}} /></Box>
          <Titulo sx={{ fontSize: "1.1rem",  textAlign:'center' }}>CNPJ:</Titulo>
          <Subtitulo sx={{ textAlign:'center'}}>{dadosCorretora?.cnpj}</Subtitulo>
        </Grid>
        <Grid
          lg={4}
          md={12}
          className={classes.Card}
        >
            <Box sx={{m:'auto', textAlign:'center'}}>  <ApartmentIcon sx={{m:'auto', textAlign:'center'}} /></Box>
          <Titulo sx={{ fontSize: "1.1rem", textAlign:'center' }}>Razão Social:</Titulo>
          <Subtitulo sx={{ textAlign:'center'}}>{dadosCorretora?.razaoSocial}</Subtitulo>
        </Grid>{" "}
        <Grid
          lg={4}
          md={12}
          className={classes.Card}
        >
            <Box sx={{m:'auto', textAlign:'center'}}>  <LocationOnIcon sx={{m:'auto', textAlign:'center'}} /></Box>
      <Titulo sx={{ fontSize: "1.1rem", textAlign:"center"}}>Endereço:</Titulo>
      <Subtitulo sx={{ textAlign:'center'}}>{dadosCorretora?.endereco}</Subtitulo>
      </Grid>
      </Grid>
      <Grid lg={10}   sx={{p:2}}>
            <Titulo sx={{ml:3, fontWeight:'bold'}}>Como podemos ajudar?</Titulo>
            </Grid>
      <Grid lg={10}   className={classes.Grid}>
      <Grid
          lg={4}
          md={12}
          className={classes.Card}
         
        >
      {dadosCorretora?.contatos?.length > 0 && (
        <>
        <Box sx={{m:'auto', textAlign:'center'}}>  <CallIcon sx={{m:'auto', textAlign:'center'}} /></Box>
      
          <Titulo sx={{ fontSize: "1.2rem", mb:1, fontWeight:500, textAlign:"center" }}>Contatos:</Titulo>
          {dadosCorretora.contatos.map((item, index) => (
            <Fragment key={index}>
              <Subtitulo sx={{textAlign:"center"}}>
                {item.tipoContato.descricao} - {item.descricao}
              </Subtitulo>
            </Fragment>
          
          ))}
         
        </>
        
      )}
       </Grid>
         </Grid>
    </>
  );
}
