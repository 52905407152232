import { useState, useEffect } from "react";
import { getDadosBasicos } from "../../../api/Cadastro";
import CampoTexto from "../../Campos/CampoTexto";
import useStyle from "./styles";
import DadosPessoaFisica from "./DadosPessoaFisica";
import DadosPessoaJuridica from "./DadosPessoaJuridica";
import DadosPessoaEstrangeira from "./DadosPessoaEstrangeira";
import { Grid, Skeleton, Typography } from "@mui/material";
import Subtitulo from "../../Textos/Subtitulo";
import CustomGrid from "../../GridCadastroCustom";
import theme from "../../Paleta/theme";
import Alert from "@mui/material/Alert";
import InputMask from "react-input-mask";
import TextField from "@mui/material/TextField";

export default function DadosBasicos({
  idCliente,
  setDada,
  isLoading,
  idPerfilLimiteOperacional,
  setNome,
  setDisabledButton = null,
}) {
  const classes = useStyle();
  const [dadosForm, setDadosForm] = useState({});
  const [haveNome, setHaveNome] = useState(true);

  useEffect(() => {
    init();
  }, [idPerfilLimiteOperacional]);

  const init = async () => {
    const response = await getDadosBasicos(
      idCliente,
      idPerfilLimiteOperacional
    );
    let campoNome = response?.campos?.find(
      ({ propriedade }) => propriedade === "Nome"
    );

    setHaveNome((prevState) => !!campoNome.valor);

    setDadosForm(response);
    setDada(response);
  };

  const handlerChangeValue = (e) => {
    if (e.target.name === "Nome") {
      setNome(e.target.value);
    }

    const newValue = dadosForm.campos.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, ["valor"]: e.target.value };
      else return c;
    });

    setDadosForm({ ...dadosForm, ["campos"]: newValue });
    setDada({ ...dadosForm, ["campos"]: newValue });
  };

  return (
    <>
      <div className={classes.box}>
        {isLoading ? (
          <Skeleton animation="wave" width={"10%"} height={30} />
        ) : (
          <Subtitulo
            sx={{ color: theme.palette.primary.main, fontWeight: 500 }}
            color="primary"
            label="Dados Pessoais"
          />
        )}
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mb: 2 }}
        >
          {dadosForm &&
            dadosForm.campos &&
            dadosForm.campos.map((c, i, array) => (
              <CustomGrid index={i} array={array} md={4}>
                {c.tipoControle === "Texto" &&
                  c.tipoMascara === null &&
                  (isLoading ? (
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  ) : (
                    <CampoTexto
                      key={i}
                      enable={c.enable}
                      variant="filled"
                      size="small"
                      fullWidth={false}
                      defaultValue=" "
                      label={c.label}
                      name={c.propriedade}
                      value={c.valor}
                      handlerChange={handlerChangeValue}
                      className={classes.campo}
                      autoFocus
                      // isLoading={isLoading}
                      maximoDigitos={c.maxLength}
                    />
                  ))}

                {c.tipoMascara === "Celular" &&
                  (isLoading ? (
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  ) : (
                    <>
                      <InputMask
                        onChange={handlerChangeValue}
                        mask={"(99) 99999-9999"}
                        value={c.valor}
                        enable={c.enable}
                        maskChar="_"
                      >
                        {() => (
                          <TextField
                            margin="normal"
                            required={c.required}
                            fullWidth={true}
                            label={c.label}
                            maxLength={c.maxLength}
                            name={c.propriedade}
                            value={c.valor}
                            size="small"
                            className={classes.campo}
                            onChange={handlerChangeValue}
                          />
                        )}
                      </InputMask>
                    </>
                  ))}

                {c.tipoMascara === "TelefoneFixo" &&
                  (isLoading ? (
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  ) : (
                    <>
                      <InputMask
                        onChange={handlerChangeValue}
                        mask={"(99) 9999-9999"}
                        value={c.valor}
                        enable={c.enable}
                        maskChar="_"
                      >
                        {() => (
                          <TextField
                            margin="normal"
                            required={c.required}
                            fullWidth={true}
                            label={c.label}
                            maxLength={c.maxLength}
                            // isLoading={true}
                            name={c.propriedade}
                            value={c.valor}
                            size="small"
                            className={classes.campo}
                            onChange={handlerChangeValue}
                          />
                        )}
                      </InputMask>
                    </>
                  ))}

                {c.tipoMascara === "CPF" &&
                  (isLoading ? (
                    <Skeleton animation="wave" width={"100%"} height={60} />
                  ) : (
                    <>
                      <InputMask
                        onChange={handlerChangeValue}
                        mask={"999.999.999-99"}
                        value={c.valor}
                        disabled={!c.enable}
                        maskChar="_"
                      >
                        {() => (
                          <TextField
                            margin="normal"
                            required={c.required}
                            fullWidth={true}
                            label={c.label}
                            maxLength={c.maxLength}
                            // isLoading={true}
                            disabled={
                              c.propriedade === "NumeroDocumentoIdentificacao"
                                ? true
                                : c.enable
                            }
                            name={c.propriedade}
                            value={c.valor}
                            size="small"
                            className={classes.campo}
                            onChange={handlerChangeValue}
                          />
                        )}
                      </InputMask>
                    </>
                  ))}
              </CustomGrid>
            ))}

          {!haveNome && (
            <>
              {isLoading ? (
                <Skeleton animation="wave" height={50} />
              ) : (
                <>
                  <Alert className={classes.Alert} severity="info">
                    <Typography sx={{ fontSize: 13 }}>
                      Escreva o seu nome completo exatamente como está no seu
                      documento de identidade.
                    </Typography>
                  </Alert>
                </>
              )}
            </>
          )}
        </Grid>

        {dadosForm.dadosPessoaFisica && dadosForm.dadosPessoaFisica.campos && (
          <DadosPessoaFisica
            classes={classes}
            dadosForm={dadosForm}
            setForm={setDadosForm}
            setData={setDada}
            isLoading={isLoading}
            setDisabledButton={setDisabledButton}
          />
        )}

        {dadosForm.dadosPessoaJuridica && (
          <DadosPessoaJuridica
            classes={classes}
            dadosForm={dadosForm}
            setForm={setDadosForm}
            setData={setDada}
            isLoading={isLoading}
            setDisabledButton={setDisabledButton}
          />
        )}

        {dadosForm.dadosPessoaEstrangeira && (
          <DadosPessoaEstrangeira
            classes={classes}
            dadosForm={dadosForm}
            setForm={setDadosForm}
            setData={setDada}
            isLoading={isLoading}
            setDisabledButton={setDisabledButton}
          />
        )}
      </div>
    </>
  );
}
