import { makeStyles } from "@mui/styles";

export const Style = makeStyles((theme) => ({
  ContainerSearch:{
    display: "flex",
    flexDirection: "row",
    margin:"10px 20px",
    width: "100%"
  },
  FormControl: {
    width: "100%",
    border: "none !important",
  },
  Seletor: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
     marginRight:10,
     border:'none !important'
    },
 
  },
  ButtonBuscar:{
    width: "100%",
    marginLeft:0,
    [theme.breakpoints.up("sm")]: {
      marginTop:'2.5%', height:'80px', width:'100%',
      marginLeft:20,
    },
  },
  Vitrine:{
    display: "flex",
                justifyContent: "center",
                background: "#fff",
                padding: 10,
                paddingTop:20,
                margin:20,
                borderRadius: 15,
              border:"1px solid #ccc"
  },
  Container:{
    display: "block",
    padding:20,
   
  },
  passageiros:{
    width: "100%",
    marginLeft:0,
    [theme.breakpoints.up("sm")]: {
      marginTop:'2.5%', height:'80px', width:'100%',
      marginLeft:10,
    },
  }
}));
