import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({

    containerOrdem: {
        display: 'flex',
        flexDirection: 'column',
        // maxHeight: '50vh !important',
        // overflow: 'scroll !important',
        [theme.breakpoints.up("sm")]: {
            width: '100%',
        },
        [theme.breakpoints.up("md")]: {
            width: '95%',
        },
        [theme.breakpoints.up("lg")]: {
            width: '100%',
        },

        [theme.breakpoints.up("xl")]: {
            width: '100%',
        },
    },



    ordemPagamento: {
        textAlign: 'start !important',
        display: 'flex',
        width: '100% !important',
        backgroundColor: '#fff !important',
        justifyContent: 'flex-start !important',
        alignItems: 'center !important',
        transition: 'all .4s ease-in-out',
        marginBottom: '.3rem !important',
        border: '1px solid !important',
        borderColor: 'transparent !important',

        '&:hover': {
            color: theme.palette.primary.main,
        },

        '& svg': {
            fontSize: 'inherit !important',
            alignSelf: 'center !important',
            transition: 'all .5s ease-in-out',
        },

        '&:hover': {
            borderColor: theme.palette.primary.main + " !important",
            "& span": {
                color: theme.palette.primary.main + " !important",

            },
            '& svg': {
                color: theme.palette.primary.main + " !important",

            }
        }
    },


    Bandeira: {
        height: '1.4rem',
        width:'1.4rem',
        borderRadius:'50%',
      
        objectFit: 'cover',
        marginRight: theme.spacing(1)
    }




}));

export default useStyles;