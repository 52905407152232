import { useState } from "react";
import DocumentoCard from "../../DocumentoCard/index";
import { useTheme } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { Grid, Typography, Divider, Box, Fade } from "@mui/material";
import { Skeleton } from "@mui/material";
import Button from "@mui/material/Button";
import Alert from "../../Snack/Snack";
import { addDocumentoCliente } from "../../../api/chamadasApi";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { CLIENTEDOCUMENTOS } from "../../../util/typesReactQuery";
import { useMutation, useQueryClient } from "react-query";
import useStyles from "./styles";
import { ButtonCustom } from "../../Botoes/Button";

const Documento = ({ documentos, loading, tiposDocumento, userID }) => {
  const initialStateDoc = {
    idTipoDocumento: 0,
    base64: "",
    nomeArquivo: "",
    idCliente: userID,
    contentType: "",
    tamanho: 0,
  };
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [tipoAlert, setTipoAlert] = useState("success");

  const [openAddDoc, setOpenAddDoc] = useState(false);
  const [formData, setFormData] = useState(initialStateDoc);

  const theme = useTheme();
  const queryClient = useQueryClient();

  const mutation = useMutation(addDocumentoCliente, {
    onSuccess: (response) => {
      if (response.errorMessage && response.errorMessage.length > 0) {
        setTipoAlert("error");
        setShowAlert(true);
        setAlertText(response.errorMessage);
      } else {
        setTipoAlert("success");
        setShowAlert(true);
        setAlertText("Documento anexado com sucesso");
        setFormData(initialStateDoc);
      }
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      setTipoAlert("error");
      setShowAlert(true);
      setAlertText(
        "Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - " +
        err
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      queryClient.invalidateQueries(CLIENTEDOCUMENTOS);
    },
  });

  const handleChangeTipoDocumento = (event) => {
    setFormData({
      ...formData,
      idTipoDocumento: event.target.value,
    });
  };

  const handlerOpenDoc = (event) => {
    event.preventDefault();
    setOpenAddDoc(!openAddDoc);
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.error("Error: ", error);
    };
  };

  const handlerChangeDoc = (event) => {
    let file = event.target.files[0];
    // transforma em base 64
    getBase64(file, async (result) => {
      setFormData({
        ...formData,
        nomeArquivo: file.name,
        base64: result,
        tamanho: file.size,
        contentType: file.type,
      });
    });
  };

  const handlerEnviarDoc = (event) => {
    event.preventDefault();
    mutation.mutate(formData);
  };

  const classes = useStyles();
  return (
    <>
      <Alert
        severity={tipoAlert}
        text={alertText}
        show={showAlert}
        close={() => setShowAlert(!showAlert)}
      />
      <Grid item xs={12} className={classes.container}>
        <Grid
          item
          xs={10}
          container
          sx={{ pl: 2, display: "flex", justifyContent: "space-between" }}
        >
          <Typography sx={{ mt: 0 }} variant="h6">
            Adicionar novo documento
          </Typography>
        </Grid>

        <Box className={classes.Box}>
          <Button component="label" size="small" onClick={handlerOpenDoc}>
            <DriveFolderUploadIcon />
          </Button>
          <Typography>
            Faça o upload do seu documento.{" "}
            <Button component="label" size="small" onClick={handlerOpenDoc}>
              Adicionar Documento
            </Button>
          </Typography>
          <Typography color="text.secondary" variant="subtitle1">
            Seu arquivo não deve ultrapassar 5MB
          </Typography>
          {formData.tamanho > 0 && (
            <Box
              component="img"
              src={formData.base64}
              style={{
                maxHeight: "60px",
                maxWidth: "130px",
                top: "50%",
                left: "50%",
                borderRadius: 8,
              }}
            />
          )}
          {formData.tamanho > 0 && formData.idTipoDocumento > 0 && (
            <Box sx={{ mt: 1 }}>
              <Button
                sx={{ width: 150 }}
                variant="contained"
                component="label"
                size="small"
                onClick={handlerEnviarDoc}
              >
                Enviar
              </Button>
            </Box>
          )}
        </Box>
        {openAddDoc && (
          <Fade in={openAddDoc}>
            <Grid container sx={{ pl: 2, mb: 4 }}>
              <Grid item xs={12} md={6} lg={6} mr={1} mt={1}>
                <Typography sx={{ fontSize: ".875rem", mb: theme.spacing(2) }}>
                  Qual o tipo de documento?
                </Typography>
                <FormControl
                  error={formData.tamanho > 0 && formData.idTipoDocumento <= 0}
                >
                  <Select
                    displayEmpty
                    className={classes.input}
                    value={formData.idTipoDocumento}
                    onChange={handleChangeTipoDocumento}
                  >
                    <MenuItem value={0}>Escolha uma opção</MenuItem>
                    {tiposDocumento.data.map((i) => {
                      return <MenuItem value={i.id}>{i.descricao}</MenuItem>;
                    })}
                  </Select>
                  {formData.tamanho > 0 && formData.idTipoDocumento <= 0 && (
                    <FormHelperText>
                      É necessário escolher uma opção
                    </FormHelperText>
                  )}



                  <ButtonCustom
                    sx={{ mt: 3 }}
                    variant="contained"
                    component="label"
                    size="small"
                    onChange={handlerChangeDoc}
                  >
                    <input type="file" id="file" accept="image/*" hidden />
                    Escolher arquivo <DriveFolderUploadIcon fontSize="small" sx={{ ml: 1 }} />
                  </ButtonCustom>

                </FormControl>
              </Grid>

              <Grid
                container
                xs={12}
                md={2}
                mt={1}
                lg={2}
                mr={1}
                sx={{ alignItems: "center", justifyContent: "flex-end" }}
              ></Grid>
            </Grid>
          </Fade>
        )}
        <Divider />
        <Grid
          container
          sx={{ pl: 2, display: "flex", justifyContent: "space-between" }}
        >
          <Typography sx={{ mb: 1, mt: 2 }} variant="h6">
            Meus documentos
          </Typography>
        </Grid>
        <Grid container sx={{ pl: 2 }}>
          {loading ? (
            <>
              <Grid item xs={12} lg={8}>
                <Skeleton
                  variant="rectangular"
                  height={20}
                  key={1}
                  sx={{ mt: 3, mb: 2, m: 1, borderRadius: 2, zindex: 1000 }}
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                <Skeleton
                  variant="rectangular"
                  height={20}
                  key={2}
                  sx={{ mt: 3, mb: 2, m: 1, borderRadius: 2, zindex: 1000 }}
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                <Skeleton
                  variant="rectangular"
                  height={20}
                  key={3}
                  sx={{ mt: 3, mb: 2, m: 1, borderRadius: 2, zindex: 1000 }}
                />
              </Grid>
            </>
          ) : (
            documentos &&
            documentos?.map((page) =>
              page?.data?.map(
                (doc, index) =>
                  doc.base64 !== "" && (
                    <Grid item xs={12} lg={8} sx={{ mt: 3 }}>
                      <Box
                        fullWidth
                        mb={2}
                        sx={{
                          width: "100%",
                          display: "flex !important",
                          justifyContent: "center !important",
                        }}
                        key={index}
                      >
                        <DocumentoCard documento={doc} key={index} />
                      </Box>
                    </Grid>
                  )
              )
            )
          )}

          {documentos === undefined && (
            <Typography
              sx={{ fontSize: ".875rem", mb: theme.spacing(2), pl: 2 }}
            >
              Ainda não há documentos anexados em seu cadastro
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Documento;
