export const CANALBANCARIOEXTERIOR = "@CANAL_BANCARIO_EXTERIOR";
export const CLIENTEISTOKENATIVO = "@GET_ISTOKEN_ATIVO";
export const CLIENTESALOD = "@CLIENTE_SALDO"
export const CLIENTEDOCUMENTOS = "@CLIENTE_DOCUMENTOS";
export const CLIENTEENDERECOS = "@CLIENTE_ENDERECOS";
export const COMPOSICAOREMESSA = "@COMPOSICAO_REMESSA";
export const NOTIFICATION = "@NOTIFICATION";
export const HISTORICOSALDOCLIENTE = "@HISTORICO_SALDO_CLIENTE";
export const VITRINEREMESSA = "@VITRINE_REMESSA";
export const VITRINERECEBIMENTO = "@VITRINE_RECEBIMENTO";
export const VITRINEOURO = "@VITRINE_OURO";
export const VITRINETURISMO = "@VITRINE_TURISMO";
export const NATUREZAOPERACAO = "@NATUREZA_OPERACAO";
export const HORARIOFUNCIONAMENTO = "@HORARIO_FUNCIONAMENTO";
export const PEDIDOCOMPROVANTES = "@PEDIDO_COMPROVANTES";
export const TIPOCLIENTEDOCUMENTOS = "@CLIENTE_TIPO_DOCUMENTOS";
export const FORMAPAGEMENTOOPERACAO = "@OPERACAO_FORMAPAGAMENTO";
export const PAIS = "@PAIS";
export const PAISPAGADORRECEBEDOR = "@PAIS_PAGADORRECEBEDOR";
export const TIPOSPAGADORRECEBEDOR = "@TIPOS_PAGADORRECEBEDOR";
export const PAGADORRECEBEDORIDPAISIDCLIENTEIDTIPO = "@PAGADORRECEBEDOR_IDPAIS_IDCLIENTE_IDTIPO";
export const CARRINHOTEMPORARIO = "@CARRINHO_TEMPORARIO"
export const TIPOSPROPRIEDADEPAGADORRECEBEDORIDPAIS = "@TIPOS_PROPRIEDADE_PAGADORRECEBEDOR_IDPAIS";
export const MEUSPEDIDOSTURISMO = "@MEUSPEDIDOS_TURISMO";
export const MEUSPEDIDOSCOMERCIAL = "@MEUSPEDIDOS_COMERCIAL";
export const MEUSPEDIDOSCAMBIO = "@MEUSPEDIDOS_CAMBIO";
export const TIPOSMERCADO = "@TIPOS_MERCADO";
export const DETALHEPEDIDOCOMERCIAL = "@DETALHE_PEDIDO_COMERCIAL";
export const DETALHEPEDIDOTURISMO = "@DETALHE_PEDIDO_TURISMO";
export const PONTOSRETIRADA = "@PONTOS_RETIRADA";
export const REGIOESENTREGA = "@REGIOES_ENTREGA";
export const GETCARRINHOTEMPORARIO = "@GET_CARRINHO_TEMPORARIO";
export const GETCARRINHOTEMPORARIORECEBIMENTO = "@GET_CARRINHO_TEMPORARIO_RECEBIMENTO";
export const GETCUPOM = "@GET_CUPOM";
export const BANCOSNACIONAIS = "@GET_BANCOS_NACIONAIS";
export const PEDIDOCONCLUIDOPAGAMENTOESCOLHIDO = "@GET_FORMA_PAGAMENTO_PEDIDO_FINALIZADO";
export const LOJAFECHADA = "@LOJA_FECHADA";
export const DADOSBANCARIOS = "@DADOS_BANCARIOS";
export const GETALLITENSCARRINHO = "@GET_ALL_iTENS_CARRINHO";
export const GETITENSTURISMOFINALIZACAOPEDIDO = "@GET_ITENS_TURISMO_FINALIZACAO_PEDIDO";
export const GETVALORSEDEX = "@GET_VALOR_SEDEX";
export const GETALLVITRINESISHABILITADA = "@GET_VITRINES_HABILITADAS";
export const GETTERMORESPONSABILIDADE = "@GET_TERMO_RESPONSABILIDADE";
export const GETVALORESITEMSTURISMO = "@GET_VALORES_ITEM_TURISMO";
export const GETVALORESITEMSREMESSA = "@GET_VALORES_ITEM_REMESSA";
export const GETVALORESITEMSRECEBIMENTO = "@GET_VALORES_ITEM_RECEBIMENTO";
export const GETVALORESITEMSOURO = "@GET_VALORES_ITEM_OURO";
export const ORDEMPAGAMENTO = "@GET_ORDEM_PAGAMENTO";
export const ORDEMPAGAMENTOBYID = "@GET_ORDEM_OBYID";
export const GETITENSCARRINHOOURO = "@GET_ITENS_CARRINHO_OURO";
export const GETDADOSCORRETORA = '@GET_DADOS_CORRETORA';