import { useEffect, useState } from "react";
import { Box, Grid, IconButton, Skeleton } from "@mui/material";
import { CampoTexto, ButtonAcaoGrande, Titulo, IconButtonDelete } from "../";
import { AddCupomCarrinho, RemoveCupomCarrinho } from "../../api/Cupom";
import TiposPedido from "../../util/typesTipoPedido";
import useAlert from "../../hooks/useAlert";
import { useMutation } from "react-query";
import { useAuth } from "../../hooks/AuthContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { ButtonCustom } from "../Botoes/Button";

const CupomDesconto = ({
  cupom,
  idTipoMercado,
  idMoeda,
  isLoading,
  setIsLoading,
  onReflesh,
  idTipoPedido,
}) => {
  const [codigoCupom, setCodigo] = useState("");
  const { Alert, showAlertError, showAlertSuccess } = useAlert();
  const { user } = useAuth();

  const mutationAddCupom = useMutation(AddCupomCarrinho, {
    onSuccess: (data) => {
      if (!data) {
        showAlertError(
          "Ops, Não conseguimos anexar o seu cupom, tente novamente ou entre em contato com nosso suporte tecnico - RKNNZ"
        );
        return;
      }

      if (data?.errorMessage?.length > 0) {
        showAlertError(`Ops, ${data.errorMessage} - RKW93`);
        return;
      }

      showAlertSuccess("Oba, seu cupom foi verifica e aceito com sucesso!")


      onReflesh();
    },

    onError: (error) => {
      showAlertError(`${error.message} - RPFH8`);
    },
    onSettled: () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    },
  });

  const mutationRemoveCupom = useMutation(RemoveCupomCarrinho, {
    onSuccess: (data) => {
      if (!data) {
        showAlertError(
          "Ops, Não conseguimos anexar o seu cupom, tente novamente ou entre em contato com nosso suporte tecnico - RA5B4"
        );
        return;
      }

      if (data?.errorMessage?.length > 0) {
        showAlertError(`${data.errorMessage} - R0487`);
        return;
      }

      if (!data?.status === 200) {
        showAlertError(
          `Ops, deu algo errado, informe ao suporte Tecnico: Erro ao selecionar a forma de pagamento - R99A0`
        );
        return;
      }

      onReflesh();
    },

    onError: (error) => {
      showAlertError(` ${error.message} - R09E8`);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const handleAddCupom = (event) => {
    setIsLoading(true);

    mutationAddCupom.mutate({
      userID: user.id,
      codigoCupom,
      idTipoPedido,
    });
  };

  const handleRemoveCupom = () => {
    setIsLoading(true);
    mutationRemoveCupom.mutate({
      userID: user.id,
      idTipoPedido,
    });
    setCodigo("");
  };

  const handleChangeCodigo = (event) => {
    setCodigo(event.target.value);
  };

  return (
    <>
      <Alert />
      <Grid
        container
        spacing={1}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Grid sx={{ mt: 1 }} item xs alignSelf="center">
          {isLoading ? (
            <Skeleton height={60} animation="wave" />
          ) : (
            <>
              {cupom ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: 0,
                  }}
                >
                  <Box>
                    <Titulo
                      sx={{ fontSize: 20 }}
                      label="Cupom inserido:"
                    ></Titulo>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 0,
                    }}
                  >
                    <Titulo
                      sx={{
                        fontWeight: "bold",
                        textAlign: "end",
                        fontSize: 20,
                      }}
                      color="primary"
                      align="right"
                    >
                      {cupom?.codigo}
                    </Titulo>
                    {cupom && (
                      <>
                        {isLoading ? (
                          <Skeleton height={60} animation="wave" />
                        ) : (
                          <IconButtonDelete onClick={handleRemoveCupom} />
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              ) : (
                <CampoTexto
                  sx={{ width: "100%" }}
                  fullWidth={true}
                  label="Cupom"
                  size="small"
                  value={codigoCupom}
                  enable
                  handlerChange={handleChangeCodigo}
                ></CampoTexto>
              )}
            </>
          )}
        </Grid>

        {!cupom && (
          <Grid item xs={3} alignSelf="center">
            {isLoading ? (
              <Skeleton height={60} animation="wave" />
            ) : (
              <ButtonAcaoGrande
                color="success"
                sx={{ ml: 0, p: "8px !important" }}
                texto="Adicionar"
                disabled={!!!codigoCupom}
                Handle={handleAddCupom}
              />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CupomDesconto;
