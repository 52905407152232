import { useState, useEffect } from "react";
import CampoTexto from "../../Campos/CampoTexto";
import CampoNumber from "../../Campos/CampoNumber";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import Seletor from "../../Seletor";
import Subtitulo from "../../Textos/Subtitulo";
import { Grid, Skeleton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import useStyle from "./styles";
import { getSocio } from "../../../api/Cadastro";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import CustomGrid from "../../GridCadastroCustom";

export default function SocioItem({
  idCliente,
  idPerfilLimiteOperacional,
  setDada,
  isLoading,
}) {
  const classes = useStyle();
  const [dadosForm, setDadosForm] = useState({});
  /* Inicia como solteiro */
  const [idTipoEstadoCivil, setIdTipoEstadoCivil] = useState(1);

  useEffect(() => {
    init(idTipoEstadoCivil);
  }, []);

  const init = async (idTipoEstadoCivil) => {
    const response = await getSocio(
      idCliente,
      idTipoEstadoCivil,
      idPerfilLimiteOperacional
    );
    setDadosForm(() => response);
    setDada(() => response);
  };

  const handlerChangeValue = (e) => {
    const newValue = dadosForm.campos.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, valor: e.target.value };
      else return c;
    });

    setDadosForm(() => ({ ...dadosForm, campos: newValue }));
    setDada(() => ({ ...dadosForm, campos: newValue }));
  };

  const handleChangeCheck = (e) => {
    const newValue = dadosForm.campos.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, valor: e.target.checked };
      else return c;
    });

    setDadosForm(() => ({ ...dadosForm, campos: newValue }));
    setDada(() => ({
      ...dadosForm,
      campos: newValue,
    }));
  };

  const handlerChangeSelect = (name, value) => {
    setDadosForm(() => ({ ...dadosForm, [name]: value }));
    setDada(() => ({ ...dadosForm, [name]: value }));

    if (name == "idTipoEstadoCivil") {
      setIdTipoEstadoCivil(value);
      init(value);
    }
  };

  const handlerChangeValuePercentual = (value, propriedade) => {
    const { campos } = dadosForm;

    // Encontra o objeto com a propriedade 'label' igual ao valor fornecido
    const updatedObjects = campos.map((obj) => {
      if (obj.propriedade === propriedade) {
        return {
          ...obj,
          valor: value,
        };
      }
      return obj;
    });

    setDada((prevState) => {
      return { ...prevState, campos: updatedObjects };
    });

    setDadosForm((prevState) => {
      return { ...prevState, campos: updatedObjects };
    });
  };

  return (
    <>
      <div className={classes.box}>
        {isLoading ? (
          <Skeleton animation="wave" width={"10%"} height={30} sx={{ mb: 2 }} />
        ) : (
          <Subtitulo sx={{ mb: 2 }} color="primary" label="Sócio" />
        )}

        <Grid
          container
          rowSpacing={1}
          sx={{ alignItems: "center" }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {dadosForm?.idTipoSocio > 0 && (
            <Grid item xs={6} md={3}>
              <Seletor
                isLoading={isLoading}
                setSelect={(e) => handlerChangeSelect("idTipoSocio", e)}
                list={dadosForm.tiposSocios}
                size="small"
                key={"tipoSocio"}
                label="Tipo de Sócio"
                fullWidth={true}
                value={dadosForm?.idTipoSocio}
              />
            </Grid>
          )}
          {dadosForm?.idTipoEstadoCivil > 0 && dadosForm?.idTipoEstadoCivil && (
            <Grid item xs={6} md={3}>
              <Seletor
                isLoading={isLoading}
                setSelect={(e) => handlerChangeSelect("idTipoEstadoCivil", e)}
                list={dadosForm.tiposEstadoCivil}
                size="small"
                key={"tipoEstadoCivil"}
                label="Tipo Estado Civil"
                fullWidth={true}
                value={dadosForm?.idTipoEstadoCivil}
              />
            </Grid>
          )}
          {dadosForm?.campos &&
            dadosForm?.campos.map((c, i, array) => (
              <CustomGrid index={i} array={array} md={3}>
                <>
                  {c.tipoMascara === "Celular" &&
                    (isLoading ? (
                      <Skeleton animation="wave" width={"100%"} height={40} />
                    ) : (
                      <>
                        <InputMask
                          onChange={handlerChangeValue}
                          mask={"(99)9999-99999"}
                          value={c.valor}
                          enable={c.enable}
                          maskChar="_"
                        >
                          {() => (
                            <TextField
                              margin="normal"
                              required={c.required}
                              fullWidth={true}
                              label={c.label}
                              maxLength={c.maxLength}
                              isLoading={true}
                              name={c.propriedade}
                              value={c.valor}
                              size="small"
                              className={classes.campo}
                              autoFocus
                              onChange={handlerChangeValue}
                            />
                          )}
                        </InputMask>
                      </>
                    ))}

                  {c.tipoMascara === "Telefone" &&
                    (isLoading ? (
                      <Skeleton animation="wave" width={"100%"} height={40} />
                    ) : (
                      <>
                        <InputMask
                          onChange={handlerChangeValue}
                          mask={"(99)9999-99999"}
                          value={c.valor}
                          enable={c.enable}
                          maskChar="_"
                        >
                          {() => (
                            <TextField
                              margin="normal"
                              required={c.required}
                              fullWidth={true}
                              label={c.label}
                              maxLength={c.maxLength}
                              isLoading={true}
                              name={c.propriedade}
                              value={c.valor}
                              size="small"
                              className={classes.campo}
                              autoFocus
                              onChange={handlerChangeValue}
                            />
                          )}
                        </InputMask>
                      </>
                    ))}

                  {c.tipoMascara === "CPF" &&
                    (isLoading ? (
                      <Skeleton animation="wave" width={"100%"} height={60} />
                    ) : (
                      <>
                        <InputMask
                          onChange={handlerChangeValue}
                          mask={"999.999.999-99"}
                          value={c.valor}
                          enable={c.enable}
                          maskChar="_"
                        >
                          {() => (
                            <TextField
                              margin="normal"
                              required={c.required}
                              fullWidth={true}
                              label={c.label}
                              maxLength={c.maxLength}
                              name={c.propriedade}
                              value={c.valor}
                              size="small"
                              className={classes.campo}
                              autoFocus
                              onChange={handlerChangeValue}
                            />
                          )}
                        </InputMask>
                      </>
                    ))}

                  {c.tipoMascara === "CEP" &&
                    (isLoading ? (
                      <Skeleton animation="wave" width={"100%"} height={60} />
                    ) : (
                      <>
                        <InputMask
                          onChange={handlerChangeValue}
                          mask={"99999-999"}
                          value={c.valor}
                          enable={c.enable}
                          maskChar="_"
                        >
                          {() => (
                            <TextField
                              margin="normal"
                              required={c.required}
                              fullWidth={true}
                              label={c.label}
                              maxLength={c.maxLength}
                              name={c.propriedade}
                              value={c.valor}
                              size="small"
                              className={classes.campo}
                              autoFocus
                              onChange={handlerChangeValue}
                            />
                          )}
                        </InputMask>
                      </>
                    ))}

                  {c.tipoControle === "Texto" &&
                    c.tipoMascara === null &&
                    (isLoading ? (
                      <Skeleton animation="wave" width={"100%"} height={60} />
                    ) : (
                      <CampoTexto
                        key={i}
                        enable={c.enable}
                        variant="filled"
                        size="small"
                        fullWidth={true}
                        defaultValue=" "
                        label={c.label}
                        name={c.propriedade}
                        value={c.valor}
                        handlerChange={handlerChangeValue}
                        className={classes.campo}
                        maximoDigitos={c.maxLength}
                        required={c.obrigatorio}
                      />
                    ))}

                  {c.tipoControle === "Check" &&
                    (isLoading ? (
                      <Skeleton animation="wave" width={"100%"} height={60} />
                    ) : (
                      <FormControl
                        sx={{ mt: 1 }}
                        component="fieldset"
                        variant="standard"
                      >
                        <FormLabel component="legend">{c.label}</FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            key={i}
                            control={
                              <Checkbox
                                value={c.valor}
                                checked={c.valor}
                                onChange={handleChangeCheck}
                                inputProps={{ "aria-label": "controlled" }}
                                name={`${c.propriedade}`}
                              />
                            }
                            label="Sim"
                          />
                        </FormGroup>
                      </FormControl>
                    ))}

                  {c.tipoControle === "Numero" && (
                    <CampoNumber
                      isLoading={isLoading}
                      key={i}
                      isMonetario={c.tipoMascara === "Monetaria"}
                      enable={c.enable}
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      label={c.label}
                      name={c.propriedade}
                      value={c.valor}
                      onChange={handlerChangeValuePercentual}
                      className={classes.campo}
                      maximoDigitos={c.maxLength}
                      required={c.obrigatorio}
                    />
                  )}
                </>
              </CustomGrid>
            ))}
        </Grid>
      </div>
    </>
  );
}
