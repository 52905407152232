import { makeStyles } from "@mui/styles";
import { TableRow, TableCell, tableCellClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
const important = "!important";

const useStyles = makeStyles((theme) => ({
  limite: {
    height: "auto",
    [theme.breakpoints.up("sm")]: {
      height: "auto",
    },
  },
  Card: {
    padding: 15,
    borderRadius: "8px !important",
    marginTop: 15,
    background: "#fcfcfc" + important,
    boxShadow: "none",
    border:'1px solid #eee',
    minWidth:'auto',
    [theme.breakpoints.up("sm")]: {
      minWidth:'25em',
      padding: 15,
      borderRadius: "8px !important",
      marginTop: 15,
      background: "#fcfcfc" + important,
      boxShadow: "none !important",
      border:'1px solid #eee'


    },
  },

  Grid: {
    padding:15,
    [theme.breakpoints.up("sm")]: {
      width: "100vw",
      padding:0,

    },
  },
  containerTable: {
    "& .MuiTableCell-root": {
      fontSize: ".9rem",
      textAlign: "start",
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
    height: 30,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
paddingBottom: '0 !important',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
  },
}));

export { useStyles, StyledTableCell, StyledTableRow };
