// import { Camera, Trash } from "phosphor-react";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from "@mui/icons-material/Delete";
import html2canvas from "html2canvas";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { useState } from "react";
import { Typography } from '@mui/material';
import { Hidden } from '@mui/material';
// import { Loading } from "../Loading";
import useStyles from "./styles";


export function ScreenshotButton({ onScreenshotTaken, screenshot, isShowButton = false }) {
  const [isTakingScreenshot, setIsTakingScreenshot] = useState(false);
  const classes = useStyles();

  async function handleTakeScreenshot() {
    const canvas = await html2canvas(document.querySelector("html"));
    const base64image = canvas.toDataURL("image/png");
    onScreenshotTaken(base64image);
    setIsTakingScreenshot(false);
  }

  if (screenshot) {
    return (
      <>
        <Box className={classes.Button} sx={{ display: 'flex', width: '40%', alignItems: 'center' }}>

          {/* <Button
            style={{ background: '#de3939' }}
            variant="contained"
            onClick={() => onScreenshotTaken(null)}
          >
            <DeleteIcon fontSize='small' />
          </Button> */}
          <Hidden smDown>
            <Typography color="text.secondary" sx={{ m: 1 }}>Anexo:</Typography>
          </Hidden>
          <Box
            className={classes.img}
            component="img"
            src={screenshot}

          />
        </Box>
      </>
    );
  }

  return (
    <>
      {isShowButton &&
        <Button color="primary" variant="contained" onClick={handleTakeScreenshot}>
          {isTakingScreenshot ? (
            <Skeleton w={50} height={50} />
          ) : (
            <AttachFileIcon className="w-6 h-6 text-zinc-100" />
          )}
        </Button>
      }
    </>
  );
}
