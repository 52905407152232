import { IVariable } from "../../interfaces/IVariable";
import { createTheme, Theme } from "@mui/material/styles";
import { darkenColor } from "../../util/helpers";

export const variables: IVariable = {
  IdHotsite: 0,
  Loja: "cambionet",
  UserStorage: "WB6FsnNy8kCT5gSGcRpsHA==",
  UserToken: "UuvPsm0CY0CogydWWTdI1g==",
  UrlApi: "https://hlg-ecommerce-cbn-api.primecase.com.br/api",
  UrlLoja:"https://hlg-ecommerce-cbn-web.primecase.com.br"
};

export const template: Theme = createTheme({
  palette: {
    primary: {
      main: "#1F1646",
      light: "#1F1646",
      //lighter: "#ece6f7",
      dark: darkenColor("#1F1646", 10), // Escurece em 20%
    },
    secondary: {
      main: "#44D62C",
    },
    error: {
      main: "#a91c1c",
    },
    text: {
      primary: "#222",
      //light: "#fff",
    },
    warning: {
      main: "#ff9800",
    },
    // white: {
    //   main: "#fff",
    // },
  },

  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
  },
});
