import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import useAlert from "../../../hooks/useAlert/index";
import ButtonAcaoGrande from "../../Botoes/ButtonAcaoGrande";
import Seletor from "../../Seletor";
import Paper from "@mui/material/Paper";
import { Grid, Box, Typography, Hidden, InputAdornment, FormControl, InputLabel, Input, TextField } from "@mui/material";
import { DateRangePicker } from "rsuite";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from '@mui/icons-material/Search';
import {
  MenuDashboard,
  PaginasMenuDashboard,
  SubMenusDashBoard,
  BreadCrumbsPaginas,
} from "../..";

import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import PassageiroList from "../../PassageiroList";

import Itens from "./Itens";

import { getVitrine, addItemVitrine } from "../../../api/Carrinho/SeguroViagem";
import { useAuth } from "../../../hooks/AuthContext";

import { Style } from "./styles";


function VitrineSeguroViagem() {
  const { user } = useAuth();
  // Função para desativar datas passadas
  const isDateDisabled = (date) => {
    const today = new Date();
    return date < today;
  };

  const classes = Style();
  const { Alert, showAlertInfo, showAlertSuccess, showAlertError } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowItens, setIsShowItens] = useState(false);
  const styles = { width: "100%", display: "block", marginBottom: 10, borderRadius:'7px', border:"0.5px solid #ccc", background:"#fafafa !important" };

  const [destinos, setDestinos] = useState([]);
  const icone = (
    <Typography
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      Embarque
      <ArrowForwardIcon sx={{fontSize:12}} />
      Desembarque
    </Typography>
  );
  

  const [form, setForm] = useState({
    idDestino: 1,
    idCliente: user.id,
    idHotsite: user.idHotsite ?? 0,
    passageiros: [],
  });

  const init = async () => {
    const response = await getVitrine();
    setDestinos(response.data.destinos);
  };

  useEffect(() => {
    init();
  }, []);

  const breadCrumbs = [
    {
      label: "Seguro de viagem",
      icon: <CompareArrowsIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      label: "Compra de seguro de viagem",
      icon: <DoubleArrowIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
  ];

  const handlerChangeDestino = (id) => {
    setForm({ ...form, idDestino: id });
  };

  const handlerChangeData = (data) => {
    const de = new Date(data[0]);
    const ate = new Date(data[1]);

    let formNew = form;
    formNew.dataDe = `${de.getFullYear()}-${de.getMonth() + 1}-${de.getDate()}`;
    formNew.dataAte = `${ate.getFullYear()}-${
      ate.getMonth() + 1
    }-${ate.getDate()}`;
    setForm(formNew);
  };

  const handlerChangePassageiro = (p) => {
    setForm({ ...form, passageiros: p });
  };

  const handlerSearch = async () => {
    setIsLoading(true);
    try {
      const response = await addItemVitrine(form);

      if (response.status !== 200) throw new Error(response.data);
      setIsLoading(false);
      setIsShowItens(true);
    } catch (error) {
      setIsLoading(false);

      // showAlertError('error.message');
      showAlertError('Preencha todos os campos corretamente')
    }
  };

  return (
    <div sx={{background:"#fcfcfc"}}>
      <Alert />
      <MenuDashboard
        pagina={PaginasMenuDashboard.Remessas}
        subMenuSelecionado={SubMenusDashBoard.Envio}
      >
        <Hidden smUp>
          <Box sx={{ pl: 2 }}>
            <BreadCrumbsPaginas list={breadCrumbs} />
          </Box>
        </Hidden>

        <Hidden smDown>
          <Box sx={{ pt: 2 }}>
            <BreadCrumbsPaginas list={breadCrumbs} />
          </Box>
        </Hidden>
        <Grid
          container
          className={classes.Container}
          item
          xs={12}
          md={12}
          lg={12}
      
        >  <Grid
        
        item
        xs={12}
        md={12}
        lg={9}
        style={{
          margin:'auto',
         
          justifyContent: "center",
          background: "#fff",
      
          borderRadius: 15,
         
        }}
      >
          <Typography
            sx={{ mb: 1, fontWeight: "bold", mt: 0 }}
            variant="h6"
            color="text"
          >
            Seguro Viagem
          </Typography>
          <Typography color="text.secondary" sx={{ mb: 2 }}>
            Faça a cotação do seu Seguro Viagem
          </Typography>
</Grid>
          <Grid
            container
            item
            xs={12}
            md={12}
            lg={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid
            className={classes.Vitrine}
              container
              item
              xs={12}
              md={12}
              lg={9}
             
            >
              <Grid   className={classes.Seletor} sx={{ mt: 1, mb: 2, pr:0, borderRight:'1px solid #eee' }} lg={3} xs={12}>
                {" "}
                <Typography sx={{  opacity:0.9, fontSize:11, marginBottom:"6px" }}>
DESTINO                </Typography>
                {destinos && (
                  <Box sx={{display:"flex", alignItems:"center"}}>
                    {/* <PlaceIcon sx={{fontSize:20, mt:1.2}} color="primary"></PlaceIcon> */}
                  <Seletor
                  
               
                    setSelect={handlerChangeDestino}
                    list={destinos}
                    size="medium"
                    // label="QUAL SEU DESTINO"
                    variant="outlined"
                    className={classes.Seletor}
                    fullWidth={true}
                    value={form.idDestino}
                  
                  />
                   </Box>
                )}  
               
              </Grid>
              <Grid sx={{ mt: 1 }} lg={4} xs={12}>
                <Typography sx={{ mb: 0.6, opacity:0.9, fontSize:11 }}>
DATA DA SUA VIAGEM
                </Typography>
                <Box sx={{display:"flex", alignItems:"center"}}>
                    {/* <CalendarTodayIcon sx={{fontSize:20, mb:1}} color="primary"></CalendarTodayIcon> */}
                <DateRangePicker
                  showWeekNumbers
                  appearance="default"
                  size="lg"
                  placement="bottomEnd"
                  showOneCalendar
               
                  preventOverflow
                  ranges={[]}
                  format="dd-MM-yyyy"
                  placeholder="Ida e Volta"
                  onChange={handlerChangeData}
                  style={styles}
                  maxDate={new Date()} // Define a data máxima como a data atual
                  disabledDate={isDateDisabled} // Desativa datas passadas
                />
                </Box>
              </Grid>
              <Grid className={classes.passageiros} sx={{ mt: 1,  }} lg={2} xs={12}>
              <Typography sx={{ mb: 0,  opacity:0.8, fontSize:12 }}>
PASSAGEIROS
                </Typography>
                <Box sx={{display:"block", alignItems:"center"}}>

              
 <PassageiroList onChange={handlerChangePassageiro} />
                </Box>
             
              </Grid>
              <Grid
              className={classes.ButtonBuscar}
                 sx={{}}
                  lg={2}
                  md={12}
                >
                 
                  <ButtonAcaoGrande
                  sx={{height:50}}
                    Handle={handlerSearch}
                    texto="Buscar"
                    loading={isLoading}
                    className={classes.buttonSearch}
                  >
                 <SearchIcon sx={{mr:1}} fontSize="small"></SearchIcon>
                  </ButtonAcaoGrande>
                </Grid>
              {/* <Grid sx={{ mt: 1, width: "90%" }} xs={12} lg={10}>
                <PassageiroList onChange={handlerChangePassageiro} />
              </Grid>{" "} */}
              <Grid
                sx={{ mt: 0, display: "flex", justifyContent: "end" }}
                xs={12}
                lg={8}
              ></Grid>
              <Grid
                sx={{ mt: 0, display: "flex", justifyContent: "end" }}
                className={classes.buttonSearch}
                xs={12}
                lg={9}
              >
               
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  
                  mt:2,
                  justifyContent: "end",
                }}
                lg={10}
              >
                {/* <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={{ textAlign: "end" }}
                  textAlign="end"
                >
                  Ao continuar você aceita nossos Termos e Condições
                </Typography> */}
              </Grid>
            </Grid>{" "}
          </Grid>

          {/* <div className={classes.ContainerSearch}>
            <table className={classes.Table}>
              <tr>
                <td className={classes.TableItem}>
                  {destinos && (
                    <Seletor
                      setSelect={handlerChangeDestino}
                      list={destinos}
                      size="small"
                      label="Vai para onde?"
                      variant="outlined"
                      sx={{width:'100%'}}
                      fullWidth={true}
                      value={form.idDestino}
                    />
                  )}
                </td>
                <td className={classes.TableItem}>
                  <DateRangePicker
                    showWeekNumbers
                    onChange={handlerChangeData}
                    style={{ width: "100%" }}
                  />
                </td>
                <td className={classes.TableItem}>
                  <PassageiroList onChange={handlerChangePassageiro} />
                </td>
                <td className={classes.TableItem}>
                  <ButtonAcaoGrande
                    Handle={handlerSearch}
                    texto="Cotar agora"
                    className={classes.buttonSearch}
                  />
                </td>
              </tr>
            </table>
          </div> */}
        </Grid>
        <br />
        {isShowItens && <Itens />}
      </MenuDashboard>
    </div>
  );
}

export default VitrineSeguroViagem;
